import { ApiCallState } from './api-call-state.reducer';
import { felApiCallState } from './initial-api-call-states/fel.api-call-state';
import { newsApiCallState } from './initial-api-call-states/news.api-call-state';
import { pecApiCallState } from './initial-api-call-states/pec.api-call-state';
import { receivedInvoicesApiCallState } from './initial-api-call-states/received-invoices.api-call-state';
import { sentInvoicesApiCallState } from './initial-api-call-states/sent-invoices.api-call-state';

export const initialApiCallState: ApiCallState = {
  apiCallState: [
    felApiCallState,
    newsApiCallState,
    pecApiCallState,
    receivedInvoicesApiCallState,
    sentInvoicesApiCallState,
  ],
};
