import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'aup-confirmation-message-modal',
  templateUrl: './confirmation-message-modal.component.html',
  styleUrls: ['./confirmation-message-modal.component.scss'],
  standalone: false,
})
export class ConfirmationMessageModalComponent {
  /**
   * Title input
   * @date 10/10/2023 - 8:02:36 PM
   *
   * @type {string}
   */
  @Input() title: string;

  /**
   * Close event emitter
   * @date 10/10/2023 - 8:02:36 PM
   *
   * @type {EventEmitter<void>}
   */
  @Output() close: EventEmitter<void> = new EventEmitter();
}
