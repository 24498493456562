import moment from 'moment';
import { SuperModel } from './super-model.model';

export class InvoiceDocument extends SuperModel {
  recieverType: string;
  sectional: string;
  type: string;
  progressive: number;
  date: Date;
  numberFromXml: string;
  useNumberFromXml: boolean;

  constructor(data: any) {
    super(data);

    if (data.date) {
      this.date = moment(data.date).toDate();
    }
  }

  get serialised() {
    return {};
  }
}
