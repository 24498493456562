<form [formGroup]="formGroup" class="flex">
  <div
    class="w-[200px] sm:w-[150px]"
    *ngIf="languagesService.isTranslationsLoaded && filterDefinition?.options"
  >
    <mat-form-field appearance="outline" class="w-[200px] sm:w-[150px]" [subscriptSizing]="'dynamic'">
      <mat-select #matSelect formControlName="status" multiple="true" [panelWidth]="null">
        <aup-select-check-all
          [text]="'COMMON.SELECT_ALL' | transloco"
          [values]="filterDefinition.options"
          [model]="formControl"
        ></aup-select-check-all>
        <mat-option *ngFor="let option of filterDefinition.options" [value]="option.value">
          <aup-status-chip
            [statusesMap]="filterDefinition.statusesMap"
            [status]="option.value.toString()"
          ></aup-status-chip>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
