import moment from 'moment';
import { ESentInvoiceStatus } from '../enums';
import { SuperModel } from './super-model.model';

export class InvoiceNotification extends SuperModel {
  date: Date;
  messageId: string;
  type: ESentInvoiceStatus;

  constructor(data: any = {}) {
    super(data);

    if (data.date) {
      this.date = moment(data.date).toDate();
    }
  }

  get serialised() {
    return {};
  }
}
