import moment from 'moment';
import { SuperModel } from './super-model.model';

export class Delegator extends SuperModel {
  user: string;
  name: string;
  expirationDate: Date;
  delegationStatus: string;
  delegationAcceptedRefusedDate: Date;
  vatCode: string;
  countryId: string;
  taxCode: string;

  constructor(data: any) {
    super(data);
    this.mapDates(data);
  }

  private mapDates(data: any) {
    if (data.expirationDate) {
      this.expirationDate = moment(data.expirationDate).toDate();
    }

    if (data.delegationAcceptedRefusedDate) {
      this.delegationAcceptedRefusedDate = moment(data.delegationAcceptedRefusedDate).toDate();
    }
  }

  get serialised() {
    return {};
  }
}
