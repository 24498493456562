import { EAdvancedFilterComparator } from '@/src/app/core/enums';
import { ISelectOption } from '@/src/app/core/interfaces';
import { marker } from '@jsverse/transloco-keys-manager/marker';

export const dateNumberComparatorOptions: ISelectOption[] = [
  {
    text: marker('ADVANCED_FILTERS.COMPARATOR.GREATER_THAN'),
    value: EAdvancedFilterComparator.GREATER_THAN,
  },
  {
    text: marker('ADVANCED_FILTERS.COMPARATOR.LESS_THAN'),
    value: EAdvancedFilterComparator.LESS_THAN,
  },
  {
    text: marker('ADVANCED_FILTERS.COMPARATOR.EQUAL_TO'),
    value: EAdvancedFilterComparator.EQUAL_TO,
  },
  {
    text: marker('ADVANCED_FILTERS.COMPARATOR.FROM_TO'),
    value: EAdvancedFilterComparator.FROM_TO,
  },
];

export const textComparatorOptions: ISelectOption[] = [
  {
    text: marker('ADVANCED_FILTERS.COMPARATOR.CONTAINS'),
    value: EAdvancedFilterComparator.CONTAINS,
  },
  {
    text: marker('ADVANCED_FILTERS.COMPARATOR.DOES_NOT_CONTAIN'),
    value: EAdvancedFilterComparator.DOES_NOT_CONTAIN,
  },
  {
    text: marker('ADVANCED_FILTERS.COMPARATOR.EQUALS'),
    value: EAdvancedFilterComparator.EQUALS,
  },
];
