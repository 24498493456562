import { News } from './news.model';
import { SuperModel } from './super-model.model';

export class Dashboard extends SuperModel {
  proxyToBeAcceptedPEC: number;
  delegatedUsersPEC: number;
  proxyToBeAcceptedFEL: number;
  delegatedUsersFEL: number;
  msgToReadPEC: number;

  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
