import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * UsageIndicatorComponent
 * @date 10/13/2023 - 12:41:30 PM
 *
 * @export
 * @class UsageIndicatorComponent
 * @typedef {UsageIndicatorComponent}
 * @implements {OnChanges}
 */
@Component({
  selector: 'aup-usage-indicator',
  templateUrl: './usage-indicator.component.html',
  styleUrls: ['./usage-indicator.component.scss'],
  standalone: false,
})
export class UsageIndicatorComponent implements OnChanges {
  /**
   * Total value
   * @date 10/13/2023 - 12:41:30 PM
   *
   * @type {number}
   */
  @Input() total: number;
  /**
   * Used value
   * @date 10/13/2023 - 12:41:30 PM
   *
   * @type {number}
   */
  @Input() usage: number;
  /**
   * Usage threshold when indicator change color to red
   * @date 10/13/2023 - 12:41:30 PM
   *
   * @type {number}
   */
  @Input() usageThreshold: number = 60;

  /**
   * Percentage usage
   * @date 10/13/2023 - 12:41:30 PM
   *
   * @type {number}
   */
  percentUsage: number;
  /**
   * Current color of indicator
   * @date 10/13/2023 - 12:41:30 PM
   *
   * @type {string}
   */
  color: string;

  /**
   * Update percentage usage and color on input change
   * @date 10/13/2023 - 12:41:30 PM
   *
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.total || !this.usage) {
      this.percentUsage = null;
      return;
    }
    this.percentUsage = (this.usage / this.total) * 100;

    if (this.percentUsage > 100) {
      this.percentUsage = 100;
    }

    if (this.percentUsage > this.usageThreshold) {
      this.color = '#D0021B';
    } else {
      this.color = '#1474bd';
    }
  }
}
