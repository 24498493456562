<ng-container *ngIf="activeFilters$ | async as activeFilters">
  <ng-container *ngIf="activeFilters?.length && (!isLoading || totalItems > 0)">
    <div class="flex items-center">
      <mat-chip class="!bg-white aup-filter-chip">
        {{ 'LIST_FILTERS.FILTERED_RESULTS' | transloco: { count: totalItems || 0 } }}
      </mat-chip>
      <aup-filters-reset-button
        [apiCallStateKey]="apiCallStateKey"
        (allFiltersReset)="allFiltersReset.emit()"
        class="ml-4"
      ></aup-filters-reset-button>
    </div>
  </ng-container>
</ng-container>
