import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { tap } from 'rxjs';
import { BillingValue } from '@models';
import { EBillingValueType } from '../../enums';
import { IBillingValuesGroup } from '../../interfaces';

@Injectable({ providedIn: 'root' })
export class BillingValuesService extends ApiService {
  values: { [key: string]: IBillingValuesGroup } = {};
  private sentValues: { [key: string]: IBillingValuesGroup } = {};
  private receivedValues: { [key: string]: IBillingValuesGroup } = {};

  private valuesMap: { [key: string]: EBillingValueType } = {
    causalepagamento: EBillingValueType.PAYMENT_REASON,
    modalitapagamento: EBillingValueType.PAYMENT_METHOD,
    causaletrasporto: EBillingValueType.TRANSPORT_REASON,
    nature: EBillingValueType.NATURE,
    nature_riepilogoimporti: EBillingValueType.NATURE_OF_AMOUNTS_SUMMARY,
    tipodocumento: EBillingValueType.DOCUMENT_TYPE,
    tipodocumentononelettronico: EBillingValueType.DOCUMENT_TYPE,
    tiporitenuta: EBillingValueType.TAX_TYPE,
  };

  constructor(injector: Injector) {
    super('billings/values', BillingValue, injector);
  }

  getSentValues(type: EBillingValueType): IBillingValuesGroup {
    return this.sentValues[type];
  }

  getReceivedValues(type: EBillingValueType): IBillingValuesGroup {
    return this.receivedValues[type];
  }

  loadBillingValuesSentReceived(fiscalYear: string, workedUsers: string[], isSent: boolean) {
    return this.post<BillingValue[]>({
      body: { filters: { fiscalYear, workedUsers } },
      queryParams: { type: isSent ? 'sent' : 'received' },
    }).pipe(
      tap(response => {
        const values = response.data;
        this.initValues();
        values.forEach(item => {
          if (!this.valuesMap[item.category]) {
            return;
          }

          if (isSent) {
            this.sentValues[this.valuesMap[item.category]][item.key] = item;
          } else {
            this.receivedValues[this.valuesMap[item.category]][item.key] = item;
          }
        });
      })
    );
  }

  private initValues() {
    this.values = {};
    Object.values(EBillingValueType).forEach(val => {
      this.values[val] = {};
    });
    this.sentValues = {};
    Object.values(EBillingValueType).forEach(val => {
      this.sentValues[val] = {};
    });
    this.receivedValues = {};
    Object.values(EBillingValueType).forEach(val => {
      this.receivedValues[val] = {};
    });
  }
}
