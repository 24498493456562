import { getObjValue } from '../lib/utils.js';
import curry from '../node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/curry.js';

/**
 * is null check
 *
 * @param {*boolean} includeNull: false
 * @param {*object} searchCondition: { name, value }
 * @param {*Predicator} predicator: normal predicator
 * @param {*DataObject} data
 */
var targetValueIsNull = function (includeNull, _a, predicator, data) {
  var key = _a.key;
  var targetValue = getObjValue(data, key);
  // value is null or undefined
  if (targetValue == null) {
    return includeNull;
  }
  return predicator(data);
};
const targetValueNull = curry(targetValueIsNull);
export { targetValueNull as default };
