import { SuperModel } from './super-model.model';

export class BillingJob extends SuperModel {
  errorCode: string;
  errorDescription: string;
  jobId: string;
  userName: string;
  type: string;
  scheduleType: number;
  creationDate: Date;
  executionStatus: number;
  resultProgress: number;
  resultDownload: boolean;
  downloadId: string;
  errorMessage: string;
  urlStorage: string;

  constructor(data: any = {}) {
    super(data);

    if (data.resultProgress) {
      this.resultProgress = parseInt(data.resultProgress);
    }
  }

  get serialised() {
    return {};
  }
}
