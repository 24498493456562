import targetValueArray from '../prefilters/targetValueArray.js';
import greater from './greater.js';
import equal from './equal.js';
import allPass from '../node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/allPass.js';
import complement from '../node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/complement.js';

/**
 * less check for data
 *
 * @param {*object} searchCondition: { name, value }
 * @param {*boolean} caseSensitive: false
 * @param {*DataObject} data
 */
var less = targetValueArray(allPass([complement(greater), complement(equal)]));
export { less as default };
