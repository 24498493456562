import { E2FAStatus, EPecCertificationType, EPecCos, EPecPermissionStatus, EPecStatus } from '../enums';
import { SuperModel } from './super-model.model';
import { compareDates } from '../utils/compare-dates.util';
import { convertBytesToMegaBytes } from '../utils/convert-bytes-to-megabytes.util';
import moment from 'moment';
export class Pec extends SuperModel {
  idProxy: number;
  user: string;
  name: string;
  expirationDate: Date;
  status: EPecStatus;
  messagesToRead: number;
  quota: number;
  usedQuota: number;
  certificationType: EPecCertificationType;
  automaticRenew: boolean;
  expirationDateExceeded: boolean;
  delegationAcceptedDate: Date;
  status2Fa: E2FAStatus;
  permissionUpgradeRequested: boolean;
  permissions: IPecPermission[];
  merge: IPecPermissionWithStatus[];
  cos: EPecCos;

  constructor(data: any = {}) {
    super(data);
    if (data.idProxy) {
      this.id = data.idProxy.toString();
    }

    if (this.expirationDate) {
      this.expirationDate = moment(this.expirationDate).toDate();
    }

    if (this.delegationAcceptedDate) {
      this.delegationAcceptedDate = moment(this.delegationAcceptedDate).toDate();
    }

    this.checkExpirationDate();
    this.convertQuotaToMB();
  }

  private convertQuotaToMB() {
    if (this.quota) {
      this.quota = convertBytesToMegaBytes(this.quota);
    }

    if (this.usedQuota) {
      this.usedQuota = convertBytesToMegaBytes(this.usedQuota);
    }
  }

  private checkExpirationDate() {
    if (!this.expirationDate) {
      return;
    }
    this.expirationDateExceeded = compareDates(this.expirationDate, new Date()) === -1;
  }

  get canAccess() {
    return this.status === EPecStatus.DELEGATED && !this.expirationDateExceeded;
  }

  get isDelegated() {
    return this.status === EPecStatus.DELEGATED;
  }

  get permissionsListWithStatus(): IPecPermissionWithStatus[] {
    return (
      this.merge ||
      this.permissions.map(permission => {
        return {
          permission,
          status: EPecPermissionStatus.UNCHANGED,
        };
      })
    );
  }

  static getAllStatuses() {
    return Object.values(EPecStatus);
  }

  get serialised() {
    return {};
  }
}

export interface IPecPermission {
  id: string;
  value: string;
  active: boolean;
}

export interface IPecPermissionWithStatus {
  permission: IPecPermission;
  status: EPecPermissionStatus;
}
