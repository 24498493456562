<button mat-button color="primary" [matMenuTriggerFor]="columnsSelector">
  <mat-icon svgIcon="edit-columns" iconPositionEnd></mat-icon>
  {{ 'COLUMNS_MANAGER.BUTTON.EDIT_COLUMNS' | transloco }}
</button>
<mat-menu #columnsSelector="matMenu">
  <div class="flex flex-col pl-2" *ngIf="_columnsConfig?.columns">
    <div cdkDropList (cdkDropListDropped)="drop($event)" class="max-h-[400px] overflow-auto">
      <div *ngFor="let column of _columnsConfig.columns" cdkDrag class="flex items-center p-2">
        <mat-icon class="!w-[6px] !h-[10px]" svgIcon="drag-and-drop"></mat-icon>
        <mat-checkbox
          #matCheckbox
          [checked]="column.checked"
          (click)="onCheckboxClick(matCheckbox.checked, column, $event)"
        >
          {{ column.label | transloco }}
        </mat-checkbox>
      </div>
    </div>
    <div class="pl-2 pr-4 mt-2 pb-2">
      <mat-divider></mat-divider>
      <div class="flex flex-col justify-between mt-2 gap-2">
        <button mat-stroked-button class="aup-btn-stroked-primary" (click)="onReset($event)">
          {{ 'COLUMNS_MANAGER.BUTTON.RESET' | transloco }}
        </button>
        <button mat-flat-button (click)="onSave()" color="primary">
          {{ 'COLUMNS_MANAGER.BUTTON.SAVE' | transloco }}
        </button>
      </div>
    </div>
  </div>
</mat-menu>
