import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { Delegator } from '@models';

@Injectable({
  providedIn: 'root',
})
export class DelegatorsService extends ApiService {
  constructor(injector: Injector) {
    super('billings/delegators', Delegator, injector);
  }
}
