import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  isActive = false;
  isLocked = false;

  constructor() {}

  show(loaderLock: boolean = false) {
    this.isActive = true;
    if (loaderLock) {
      this.isLocked = true;
    }
  }

  hide(loaderUnlock: boolean = false) {
    if (this.isLocked && !loaderUnlock) {
      return;
    }
    this.isLocked = false;
    this.isActive = false;
  }
}
