import { SelectionModel } from '@angular/cdk/collections';
import { SuperModel } from './super-model.model';
import { Subscription } from 'rxjs';

export class AruSelectionModel<T extends SuperModel & { position: number }> extends SelectionModel<T> {
  selectedAll: boolean;
  totalItems: number;
  deselected: Map<string, T> = new Map();

  subscription = new Subscription();

  constructor(
    _multiple?: boolean,
    initiallySelectedValues?: T[],
    _emitChanges?: boolean,
    compareWith?: (o1: T, o2: T) => boolean,
    private getItemId?: (item: T) => string
  ) {
    super(_multiple, initiallySelectedValues, _emitChanges, compareWith);
    this.listenForSelectionChange();
  }

  get isSelectionActive() {
    return this.selected.length || (this.selectedAll && this.totalItems > this.deselected.size);
  }

  get selectedCount() {
    if (!this.totalItems) {
      return 0;
    }

    return this.selectedAll ? this.totalItems - this.deselected.size : this.selected.length;
  }

  deselectAll() {
    this.selectedAll = false;
    this.clear();
    this.deselected.clear();
  }

  selectAll(items: T[]) {
    this.selectedAll = true;
    this.deselected.clear();
    this.setItemsSelected(items);
  }

  setItemsSelected(items: Array<T>) {
    const itemsToAdd = [];
    items.forEach(item => {
      if (!this.isSelected(item) && !this.deselected.has(this.getItemId(item))) {
        itemsToAdd.push(item);
      }
    });

    this.select(...itemsToAdd);
  }

  private listenForSelectionChange() {
    this.subscription.add(
      this.changed.subscribe(change => {
        change.removed.forEach(item => {
          if (!this.deselected.has(this.getItemId(item))) {
            this.deselected.set(this.getItemId(item), item);
          }
        });
        change.added.forEach(item => {
          if (this.deselected.has(this.getItemId(item))) {
            this.deselected.delete(this.getItemId(item));
          }
        });
      })
    );
  }

  onDestroy() {
    this.subscription.unsubscribe();
  }
}
