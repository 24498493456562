import { __spreadArray, __assign } from './node_modules/.pnpm/@rollup_plugin-typescript@11.1.2_rollup@3.27.0_tslib@2.6.1_typescript@5.1.6/node_modules/tslib/tslib.es6.js';
import { compose, listCombiner } from './lib/utils.js';
import filtersMap from './filters/filtersMap.js';
import targetValueNull from './prefilters/targetValueNull.js';
import curry from './node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/curry.js';
import anyPass from './node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/anyPass.js';
import filter from './node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/filter.js';
import drop from './node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/drop.js';
import take from './node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/take.js';
import transduce from './node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/transduce.js';
var optionsDefault = {
  caseSensitive: false,
  includeNull: false
};
function makeSinglePredicator(searchCondition, options, curriedFilter) {
  var partialPredicator = curriedFilter(searchCondition, options.caseSensitive);
  var predicator = targetValueNull(options.includeNull, searchCondition, partialPredicator);
  return predicator;
}
/**
 *
 * @param {*array} allData: Array of object
 * @param {*array} searchConditions
 * @param {*object} optionsIn: { caseSensitive: false, includeNull: false, offset: undefined, limit: undefined }
 */
function filterData(allData, searchConditions, optionsIn) {
  if (optionsIn === void 0) {
    optionsIn = {};
  }
  var searchConditionsValNotEmpty = searchConditions.filter(function (_a) {
    var value = _a.value;
    return value !== '' && value != null;
  });
  // return directly when no conditions available
  if (searchConditionsValNotEmpty.length === 0) {
    return __spreadArray([], allData, true);
  }
  var options = __assign(__assign({}, optionsDefault), optionsIn);
  var dataFilters = searchConditionsValNotEmpty.map(function (searchCondition) {
    var key = searchCondition.key,
      type = searchCondition.type;
    // get partial function
    var curriedFilter = curry(filtersMap[type]);
    // target key is one
    var predicator;
    if (typeof key === 'string') {
      predicator = makeSinglePredicator(searchCondition, options, curriedFilter);
    } else {
      // or search for multiple keys
      predicator = anyPass(key.map(function (oneKey) {
        return makeSinglePredicator({
          key: oneKey,
          value: searchCondition.value,
          type: searchCondition.type
        }, options, curriedFilter);
      }));
    }
    return filter(predicator);
  });
  // pagination
  if (options.offset != null && options.limit != null) {
    dataFilters.push(drop(options.offset));
    dataFilters.push(take(options.limit));
  }
  var filtersTrans = compose.apply(void 0, dataFilters);
  return transduce(filtersTrans, listCombiner, [], allData);
}
export { filterData as default };
