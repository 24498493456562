import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { filter, finalize, of, Subject, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DelegationPecMailService extends ApiService {
  private mailsRecalculation$: Subject<boolean> = new Subject();
  private enterToPecLocalStorageKey = 'ENTERED_TO_PEC';
  private inProgress = false;

  constructor(injector: Injector) {
    super('delegation/pec/mail', Object, injector);
  }

  init() {
    const backFromPEC = localStorage.getItem(this.enterToPecLocalStorageKey) === '1';
    if (!backFromPEC) {
      return;
    }

    this.onBackFromPec();
    this.inProgress = true;

    const subscription = this.authService.isAuthenticated$
      .pipe(
        filter(isAuthenticated => isAuthenticated),
        take(1),
        tap(() => {
          this.recalculateEmail();
          subscription.unsubscribe();
        })
      )
      .subscribe();
  }

  recalculateEmail() {
    this.loaderService.show(true);
    this.inProgress = true;
    this.post({ path: '/recalc', disableHideLoader: true })
      .pipe(
        finalize(() => {
          this.loaderService.hide(true);
          this.inProgress = false;
          this.mailsRecalculation$.next(true);
        })
      )
      .subscribe();
  }

  onEnterToPec() {
    localStorage.setItem(this.enterToPecLocalStorageKey, '1');
  }

  waitForRecalculation() {
    if (!this.inProgress) {
      return of(true);
    }
    return this.mailsRecalculation$.asObservable();
  }

  private onBackFromPec() {
    localStorage.removeItem(this.enterToPecLocalStorageKey);
  }
}
