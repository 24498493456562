import { Injectable } from '@angular/core';
import { BillingJobsApiService } from './api';
import { IApiRequest } from '../interfaces';
import { IBulkActionExecution } from '@interfaces';

import { BillingJob, User } from '../models';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { selectUser } from '../state/session/session.selectors';
import { Subject, Subscription, tap } from 'rxjs';
import { EBillingJobStatus } from '../enums';
import { LoaderService } from './loader.service';
import { ToastMessagesService } from './toast-messages.service';
import { marker } from '@jsverse/transloco-keys-manager/marker';

@Injectable({
  providedIn: 'root',
})
export class BillingJobsService {
  activeJobId: string;
  user: User;

  currentJobInfo$: Subject<BillingJob> = new Subject<BillingJob>();
  pollingSubscription: Subscription;

  stopPollingStatuses = [
    EBillingJobStatus.SUCCESS,
    EBillingJobStatus.ABORTED,
    EBillingJobStatus.CANCELLED_BY_USER,
    EBillingJobStatus.ERROR,
  ];

  constructor(
    private billingJobsApiService: BillingJobsApiService,
    private readonly store: Store<AppState>,
    private readonly loaderService: LoaderService,
    private readonly toastMessagesService: ToastMessagesService
  ) {
    this.listenForUser();
  }

  startInvoiceJob(
    type: 'received' | 'sent',
    apiRequest: IApiRequest,
    bulkActionExecution: IBulkActionExecution,
    fiscalYear: string
  ) {
    if (this.activeJobId) {
      this.toastMessagesService.show({
        type: 'error',
        message: marker('BILLING_JOBS_SERVICE.CANT_RUN_NEXT_JOB'),
        title: '',
      });
      return;
    }

    this.loaderService.show();
    this.billingJobsApiService
      .startJob(type, apiRequest, bulkActionExecution, this.user.username, fiscalYear)
      .subscribe(response => {
        this.activeJobId = response.data.jobId;
        this.currentJobInfo$.next(new BillingJob({ executionStatus: EBillingJobStatus.CREATED }));
        this.pollJob();
      });
  }

  init() {
    this.billingJobsApiService.getActiveJob().subscribe(activeJob => {
      if (!activeJob) {
        return;
      }
      this.activeJobId = activeJob.jobId;
      this.currentJobInfo$.next(activeJob);
      this.pollJob();
    });
  }

  killCurrentJob() {
    if (!this.activeJobId) {
      return;
    }

    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }

    this.loaderService.show();
    this.billingJobsApiService
      .post({ path: `/jobs/${this.activeJobId}/kill` })
      .pipe(
        tap(() => {
          this.activeJobId = null;
        })
      )
      .subscribe();
  }

  private pollJob() {
    this.pollingSubscription = this.billingJobsApiService
      .pollJob(this.activeJobId, this.stopPollingStatuses)
      .subscribe(response => {
        if (response.data?.urlStorage) {
          this.downloadFile(response.data.urlStorage);
          this.activeJobId = null;
          this.currentJobInfo$.next(new BillingJob({ executionStatus: EBillingJobStatus.SUCCESS }));
          return;
        }

        if (response.data.executionStatus === EBillingJobStatus.CANCELLED_BY_USER) {
          this.activeJobId = null;
          return;
        }

        if (this.stopPollingStatuses.includes(response.data?.executionStatus)) {
          this.activeJobId = null;
        }

        this.currentJobInfo$.next(response.data);
      });
  }

  private listenForUser() {
    this.store.select(selectUser).subscribe(user => {
      this.user = user;
    });
  }

  private downloadFile(url: string) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
