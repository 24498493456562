import {
  EAttachedDocumentType,
  EInvoiceFileType,
  EReceivedInvoicePaymentStatus,
  EReceivedInvoiceStatus,
} from '../enums';
import { AttachedDocument } from './attached-document.model';
import { SuperModel } from './super-model.model';
import moment from 'moment';

export class ReceivedInvoiceListItem extends SuperModel {
  username: string;
  position: number;
  sender: string;
  fileType: EInvoiceFileType;
  status: EReceivedInvoiceStatus;
  number: string;
  orderNumber: string;
  date: Date;
  receivingDate: Date;
  receivingDateRead: Date;
  type: string;
  receiver: string;
  documentTotalImport: number;
  uploadFileName: string;
  valorizedFile: boolean;
  fileName: string;
  sdiFileName: string;
  sentCorrectId: string;
  preserved: boolean;
  attached: boolean;
  imported: boolean;
  editable: boolean;
  deletable: boolean;
  documentTotal: number;
  sdiId: string;
  netToPay: number;
  vatTotal: number;
  taxableTotal: number;
  nonTaxableTotal: number;
  collectionPaymentStatus: EReceivedInvoicePaymentStatus;
  collectionPaymentDate: Date;
  installmentStatusIcon: number;
  manualNetImportToPay: number;
  xsdFormat: number;
  unsupported: boolean;
  priceMode: string;
  primaryCode: string;
  secondaryCode: string;
  personalDataAssociation: string;
  creditNoteAssociation: string;
  creditNoteAssociationPosition: number;
  associatedCreditNoteNumber: number;
  associatedCreditNoteImport: number;
  dealsVolume: number;
  autoInvoice: boolean;
  enableCreditNoteCreation: boolean;
  attachedDocuments: AttachedDocument[];
  importOrigins: string;

  constructor(data: any) {
    super(data);

    this.mapDates(data);
    this.mapAttachedDocuments(data);
  }

  get canDownloadP7M() {
    return this.valorizedFile && this.fileName.includes('p7m');
  }

  private mapAttachedDocuments(data: any) {
    if (data.attachedDocuments) {
      this.attachedDocuments = data.attachedDocuments.map(document => new AttachedDocument(document));
    }
  }

  private mapDates(data: any) {
    if (data.date) {
      this.date = moment(data.date).toDate();
    }

    if (data.receivingDate) {
      this.receivingDate = moment(data.receivingDate).toDate();
    }

    if (data.collectionPaymentDate) {
      this.collectionPaymentDate = moment(data.collectionPaymentDate).toDate();
    }

    if (data.receivingDateRead) {
      this.receivingDateRead = moment(data.receivingDateRead).toDate();
    }
  }

  override get serialised() {
    return {};
  }
}
