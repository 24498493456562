import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { IApiRequest } from '../../interfaces';
import { IBulkActionExecution } from '@interfaces';
import { BillingJob } from '../../models';
import { interval, map, of, switchMap, takeWhile } from 'rxjs';
import { EBillingJobStatus } from '../../enums';

@Injectable({
  providedIn: 'root',
})
export class BillingJobsApiService extends ApiService {
  constructor(injector: Injector) {
    super('billings', BillingJob, injector);
  }

  startJob(
    type: 'received' | 'sent',
    apiRequest: IApiRequest,
    bulkActionExecution: IBulkActionExecution,
    username: string,
    fiscalYear: string
  ) {
    const body = {
      username,
      workedUsers: this.getWorkedUsers(apiRequest),
      bulkList: bulkActionExecution.actions,
      selectedAll: bulkActionExecution.selectedAll,
      selectedId: this.getSelectedItems(bulkActionExecution),
      deselectedId: this.getDeselectedIds(bulkActionExecution),
      filterSearch: {
        ...this.mapRequestPartsToBody({ filters: apiRequest.filters }),
      },
    };
    return this.post<BillingJob>({
      path: `/invoices/${type}/job`,
      body,
      queryParams: { fiscalYear },
      entityClass: BillingJob,
    });
  }

  pollJob(jobId: string, stopPollingStatuses: EBillingJobStatus[]) {
    return interval(5000).pipe(
      switchMap(() => this.getOne<BillingJob>({ path: `/jobs/${jobId}` })),
      takeWhile(response => !stopPollingStatuses.includes(response.data.executionStatus), true),
      switchMap(response => {
        if (response.data.executionStatus === EBillingJobStatus.SUCCESS) {
          return this.getOne<BillingJob>({ path: `/downloads/${response.data.downloadId}` });
        } else {
          return of(response);
        }
      })
    );
  }

  getActiveJob() {
    const statusesOfActiveJob = [
      EBillingJobStatus.CREATED,
      EBillingJobStatus.SUBMITTED,
      EBillingJobStatus.RUNNING,
    ];

    return this.get<BillingJob>({ path: '/jobs' }).pipe(
      map(response => {
        const activeJob = response.data.find(job => statusesOfActiveJob.includes(job.executionStatus));
        return activeJob || null;
      })
    );
  }

  private getDeselectedIds(bulkActionExecution: IBulkActionExecution) {
    if (!bulkActionExecution.deselected?.size) {
      return [];
    }

    return Array.from(bulkActionExecution.deselected.values()).map(item => {
      return {
        id: item.id,
        position: item.position,
      };
    });
  }

  private getWorkedUsers(apiRequest: IApiRequest) {
    return apiRequest.filters.find(item => item.id === 'workedUsers').value;
  }

  private getSelectedItems(bulkActionExecution: IBulkActionExecution) {
    return (bulkActionExecution.items || []).map((item, index) => {
      return {
        id: item.id,
        position: item.position,
      };
    });
  }
}
