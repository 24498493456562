import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OAuthErrorEvent, OAuthService, OAuthSuccessEvent, TokenResponse } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // isAuthenticatedSubject variable
  private readonly isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  // isAuthenticated variable
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  // isDoneLoadingSubject variable
  private readonly isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  // isDoneLoading variable
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
  // canActivateProtectedRoutes variable
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map(values => values.every(b => b)));
  // redirectUrl variable
  public redirectUrl: string;

  public idpsList: string;
  /**
   * Navigates to login page
   *
   * @returns void
   */
  private navigateToLoginPage(): void {
    this.router.navigateByUrl('/sign-in');
  }
  /**
   * Constructor
   *
   * @param authService private readonlyo authService param
   * @param router private readonly router param
   *
   */
  constructor(
    private readonly oauthService: OAuthService,
    private readonly router: Router
  ) {
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
        if (event.type === 'invalid_nonce_in_state') {
          this.logout();
        }
      } else {
        console.warn('OAuthEvent Object:', event);
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', event => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn(
        'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
      );
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe(_ => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    const timeout = 1000;
    return (
      this.oauthService
        .loadDiscoveryDocument()

        // For demo purposes, we pretend the previous call was very slow
        .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), timeout)))

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oauthService.tryLogin())

        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve();
          }

          // Try to log in via a refresh because then we can prevent
          // needing to redirect the user:
          return this.oauthService
            .refreshToken()
            .then(() => Promise.resolve())
            .catch(result => {
              // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
              // Only the ones where it's reasonably sure that sending the
              // user to the IdServer will help.
              const errorResponsesRequiringUserInteraction = [
                'interaction_required',
                'login_required',
                'account_selection_required',
                'consent_required',
              ];

              if (
                result &&
                result.reason &&
                errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0
              ) {
                // 3. ASK FOR LOGIN:
                // At this point we know for sure that we have to ask the
                // user to log in, so we redirect them to the IdServer to
                // enter credentials.
                //
                // Enable this to ALWAYS force a user to login.
                this.login();
                //
                // Instead, we'll now do this:
                console.warn(
                  'User interaction is needed to log in, we will wait for the user to manually log in.'
                );
                return Promise.resolve();
              }

              // We can't handle the truth, just pass on the problem to the
              // next handler.
              return Promise.reject(result);
            });
        })

        .then(() => {
          this.isDoneLoadingSubject$.next(true);

          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (
            this.oauthService.state &&
            this.oauthService.state !== 'undefined' &&
            this.oauthService.state !== 'null'
          ) {
            let stateUrl = this.oauthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }
            this.router.navigateByUrl(stateUrl);
          }
        })
        .catch(() => this.isDoneLoadingSubject$.next(true))
    );
  }
  /**
   * Login
   *
   * @param targetUrl targetUrl param
   * @returns void
   * @example public login(targetUrl?: string): void
   *
   */
  public login(targetUrl?: string): void {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    const redirectUrl = this.redirectUrl;
    this.redirectUrl = undefined;
    this.oauthService.initLoginFlow(targetUrl || redirectUrl || this.router.url);
  }
  /**
   *
   * Logout
   *
   * @returns void
   * @example public logout(): void
   *
   */
  public logout(): void {
    localStorage.removeItem('User');
    this.oauthService.logOut();
  }
  /**
   *
   * Refresh
   *
   * @returns Promise<OAuthEvent>
   * @example public refresh(): Promise<OAuthEvent>
   *
   */
  public refresh(): Promise<TokenResponse> {
    return this.oauthService.refreshToken();
  }
  /**
   *
   * Checks valid token
   *
   * @returns boolean
   * @example public hasValidToken(): boolean
   *
   */
  public hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }
  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  /**
   * Gets access token
   *
   * @returns string
   * @example public get accessToken(): string
   *
   */
  public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }
  /**
   * Gets refresh token
   *
   * @returns string
   * @example public get refreshToken(): string
   *
   */
  public get refreshToken(): string {
    return this.oauthService.getRefreshToken();
  }
  /**
   * Gets identity claims
   *
   * @returns object
   * @example public get identityClaims(): object
   */
  public get identityClaims(): object {
    return this.oauthService.getIdentityClaims();
  }
  /**
   * Gets id token
   *
   * @returns string
   * @example public get idToken(): string
   *
   */
  public get idToken(): string {
    return this.oauthService.getIdToken();
  }
  /**
   * Gets logout url
   *
   * @returns string
   * @example public get logoutUrl(): string
   */
  public get logoutUrl(): string {
    return this.oauthService.logoutUrl;
  }
}
