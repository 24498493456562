import { getObjValue } from '../lib/utils.js';

/**
 * like check for data
 *
 * @param {*object} searchCondition: { name, value }
 * @param {*boolean} caseSensitive: false
 * @param {*DataObject} data
 */
var like = function (searchCondition, caseSensitive, data) {
  var key = searchCondition.key,
    value = searchCondition.value;
  var targetValue = getObjValue(data, key);
  if (targetValue == null) {
    return false;
  }
  // disable like search if targetValue is array
  if (Array.isArray(targetValue)) {
    return false;
  }
  if (caseSensitive) {
    return targetValue.toString().includes(value.toString());
  }
  return targetValue.toString().toUpperCase().includes(value.toString().toUpperCase());
};
export { like as default };
