import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IApiSorting, IQuickSortColumn, ISortEvent } from '@interfaces';

import { EApiCallStateKey, EApiRequestPartKeys } from '@core/state/api-call-state/api-call-state.enum';
import { map, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@core/state';
import { selectApiCallStateRequestPartByKey } from '@/src/app/core/state/api-call-state/api-call-state.selectors';
import { ApiCallStateActions } from '@/src/app/core/state/api-call-state/api-call-state.actions';
import { EApiSortDirection } from '@/src/app/core/enums';

@Component({
  selector: 'aup-quick-sort',
  templateUrl: './quick-sort.component.html',
  styleUrls: ['./quick-sort.component.scss'],
  standalone: false,
})
export class QuickSortComponent implements OnInit, OnDestroy {
  @Input() sortColumns: IQuickSortColumn[];
  @Input() apiCallStateKey: EApiCallStateKey;

  /**
   * Sorting data observable
   * @date 6/9/2024 - 12:03:45 PM
   *
   * @type {IApiSorting}
   */
  sorting: IApiSorting;

  subscription = new Subscription();
  eApiSortDirection = EApiSortDirection;

  constructor(private store$: Store<AppState>) {}

  /**
   * Initialize listener for sorting changes in store
   * @date 6/9/2024 - 12:03:45 PM
   */
  ngOnInit(): void {
    this.subscription.add(
      this.store$
        .select(
          selectApiCallStateRequestPartByKey({
            apiCallStateKey: this.apiCallStateKey,
            requestPartKey: EApiRequestPartKeys.SORTING,
          })
        )
        .subscribe(requestPart => {
          this.sorting = requestPart.data;
        })
    );
  }

  /**
   * Handle Sort event - update store
   * @date 6/9/2024 - 12:03:45 PM
   *
   * @param {Sort} sortEvent
   */
  onSortChange(property: string) {
    let direction = EApiSortDirection.ASC;
    if (this.sorting?.property === property) {
      direction =
        this.sorting.direction === EApiSortDirection.ASC ? EApiSortDirection.DESC : EApiSortDirection.ASC;
    } else {
      direction = this.sorting?.direction || EApiSortDirection.ASC;
    }

    this.store$.dispatch(
      ApiCallStateActions.updateRequestPart({
        apiCallStateKey: this.apiCallStateKey,
        requestPartKey: EApiRequestPartKeys.SORTING,
        shouldReplaceData: true,
        data: { property, direction },
      })
    );
    this.store$.dispatch(
      ApiCallStateActions.updateRequestPart({
        apiCallStateKey: this.apiCallStateKey,
        requestPartKey: EApiRequestPartKeys.PAGINATION,
        data: { page: 0 },
      })
    );

    this.store$.dispatch(ApiCallStateActions.load({ apiCallStateKey: this.apiCallStateKey }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
