import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { InvoiceFull, ReceivedInvoiceListItem, SentInvoiceListItem } from '@models';
import { IApiRequest, IApiResponse } from '../../interfaces';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService extends ApiService {
  constructor(injector: Injector) {
    super('billings/invoices', Object, injector);
  }

  listSentInvoices(request: IApiRequest): Observable<IApiResponse<SentInvoiceListItem[]>> {
    return this.post<SentInvoiceListItem[]>({
      path: '/sent',
      entityClass: SentInvoiceListItem,
      ...request,
      requestPartsInBody: true,
    });
  }

  listReceivedInvoices(request: IApiRequest): Observable<IApiResponse<ReceivedInvoiceListItem[]>> {
    return this.post<ReceivedInvoiceListItem[]>({
      path: '/received',
      entityClass: ReceivedInvoiceListItem,
      ...request,
      requestPartsInBody: true,
    });
  }

  getSentInvoiceById(request: IApiRequest): Observable<IApiResponse<InvoiceFull>> {
    return this.getById<InvoiceFull>({
      path: `/sent/${request.id}`,
      entityClass: InvoiceFull,
      ...request,
      id: undefined,
    }).pipe(
      map(response => {
        response.data.id = request.id.toString();
        response.data.position = request.queryParams.position;
        return response;
      })
    );
  }

  getReceivedInvoiceById(request: IApiRequest): Observable<IApiResponse<InvoiceFull>> {
    return this.getById<InvoiceFull>({
      path: `/received/${request.id}`,
      entityClass: InvoiceFull,
      ...request,
      id: undefined,
    }).pipe(
      map(response => {
        response.data.id = request.id.toString();
        response.data.position = request.queryParams.position;
        return response;
      })
    );
  }

  extractSentInvoicesCount(iApiRequest: IApiRequest, invoiceList: string[]): Observable<number> {
    return this.sentInvoicesCount(iApiRequest, invoiceList, 'extract');
  }

  jobSentInvoicesCount(iApiRequest: IApiRequest, invoiceList: string[]): Observable<number> {
    return this.sentInvoicesCount(iApiRequest, invoiceList, 'job');
  }

  sentInvoicesCount(
    iApiRequest: IApiRequest,
    invoiceList: string[],
    queryParams?: 'extract' | 'job'
  ): Observable<number> {
    return this.post<{ count: number }>({
      path: '/sent/count',
      body: { ...this.mapRequestPartsToBody({ filters: iApiRequest.filters }), invoiceList },
      queryParams: { type: queryParams },
    }).pipe(
      map(iApiResponse => {
        const amount = Number(iApiResponse.data?.count);
        if (isNaN(amount)) {
          console.error('Invalid response for /sent/count');
          return 0;
        }

        return amount;
      })
    );
  }

  extractReceivedInvoicesCount(iApiRequest: IApiRequest, invoiceList: string[]): Observable<number> {
    return this.receivedInvoicesCount(iApiRequest, invoiceList, 'extract');
  }

  jobReceivedInvoicesCount(iApiRequest: IApiRequest, invoiceList: string[]): Observable<number> {
    return this.receivedInvoicesCount(iApiRequest, invoiceList, 'job');
  }

  receivedInvoicesCount(
    iApiRequest: IApiRequest,
    invoiceList: string[],
    queryParams?: 'extract' | 'job'
  ): Observable<number> {
    return this.post<{ count: number }>({
      path: '/received/count',
      body: { ...this.mapRequestPartsToBody({ filters: iApiRequest.filters }), invoiceList },
      queryParams: { type: queryParams },
    }).pipe(
      map(iApiResponse => {
        const amount = Number(iApiResponse.data?.count);
        if (isNaN(amount)) {
          console.error('Invalid response for /received/count');
          return 0;
        }

        return amount;
      })
    );
  }
}
