import { SuperModel } from './super-model.model';

export class RecurringInvoice extends SuperModel {
  refId: string;
  automaticSend: boolean;

  constructor(data: any) {
    super(data);
  }

  override get serialised() {
    return {};
  }
}
