import { SuperModel } from './super-model.model';

export class BillingValue extends SuperModel {
  category: string;
  description: string;
  disableSdiVersion: string;
  disableSdiVersionAsInt: number;
  enableSdiVersion: string;
  enableSdiVersionAsInt: number;
  key: string;
  level1: string;
  order: number;
  value: string;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
