import { getObjValue } from '../lib/utils.js';
import curry from '../node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/curry.js';

/**
 * exclude data if targetValue is an array
 *
 * @param {*function} filterCallback
 * @param {*object} searchCondition: { name, value }
 * @param {*boolean} caseSensitive: false
 * @param {*DataObject} data
 */
var excludeIfTargetValueIsArray = function (predicator, searchCondition, caseSensitive, data) {
  var key = searchCondition.key;
  var targetValue = getObjValue(data, key);
  if (Array.isArray(targetValue)) {
    return false;
  }
  return predicator(searchCondition, caseSensitive, data);
};
var targetValueArray = curry(excludeIfTargetValueIsArray);
export { targetValueArray as default };
