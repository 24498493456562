import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'aup-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss'],
  standalone: false,
})
export class ItemSelectorComponent {
  @Input() type: 'item' | 'main' = 'item';
  @Input() items: any[];
  @Input() selection: SelectionModel<any>;
  @Input() row: any;

  isAllSelected() {
    const itemsIds = this.items.map(item => item.id);
    return itemsIds.reduce((prev, current) => {
      if (!prev) {
        return false;
      }
      return !!this.selection.selected.find(item => item.id === current);
    }, true);
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.deselect(...this.items);
      return;
    }

    this.selection.select(...this.items);
  }
}
