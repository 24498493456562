<mat-checkbox
  *ngIf="type === 'main'; else itemCheckbox"
  (change)="$event ? toggleAllRows() : null"
  [checked]="selection.hasValue() && isAllSelected()"
  [indeterminate]="selection.hasValue() && !isAllSelected()"
></mat-checkbox>

<ng-template #itemCheckbox>
  <mat-checkbox
    (click)="$event.stopPropagation()"
    (change)="$event ? selection.toggle(row) : null"
    [checked]="selection.isSelected(row)"
  ></mat-checkbox>
</ng-template>
