<button
  mat-stroked-button
  class="!min-w-[36px] !h-[36px] !p-0 aup-btn-stroked-primary"
  [matMenuTriggerFor]="quickSortColumns"
>
  <mat-icon
    svgIcon="quick-sort"
    class="!m-0"
    [class.rotate-180]="sorting?.direction === eApiSortDirection.ASC"
    iconPositionEnd
  ></mat-icon>
</button>
<mat-menu #quickSortColumns="matMenu" class="rounded-md">
  <div class="max-h-[400px] w-48 overflow-auto">
    <div
      *ngFor="let column of sortColumns"
      class="flex items-center justify-between p-2 w-full cursor-pointer"
      (click)="onSortChange(column.property)"
    >
      <span class="mat-body-2">
        {{ column.label | transloco }}
      </span>
      <mat-icon
        *ngIf="sorting?.property === column.property"
        svgIcon="quick-sort-direction"
        [class.rotate-180]="sorting?.direction === eApiSortDirection.ASC"
      ></mat-icon>
    </div>
  </div>
</mat-menu>
