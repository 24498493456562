import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { IStatusChip } from './status-chip.interface';

@Component({
  selector: 'aup-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
  standalone: false,
})
export class StatusChipComponent implements OnInit, OnChanges {
  @Input() statusesMap: Record<string, IStatusChip>;
  @Input() status: string | number;

  statusInfo: IStatusChip;

  defaultColors: Partial<IStatusChip> = {
    bgColor: '#F2F2F1',
    dotColor: '#222222',
  };

  ngOnInit() {
    this.updateColors();
  }

  ngOnChanges() {
    this.updateColors();
  }

  private updateColors() {
    if (this.statusesMap[this.status]) {
      this.statusInfo = this.statusesMap[this.status];
    }
  }
}
