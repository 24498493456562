import { marker } from '@jsverse/transloco-keys-manager/marker';
import { EApiCallStateKey, EApiRequestPartKeys, EApiRequestPartsNames } from '../api-call-state.enum';
import { IApiCallState } from '../api-call-state.interface';
import { SearchType } from 'filter-data';
import { EClientType, ESentInvoicePaymentStatus, ESentInvoiceStatus } from '../../../enums';
import { IApiFiltersList } from '../../../interfaces/filters.interface';
import clone from 'just-clone';

import { fileTypeFilterDefinition } from '@/src/app/modules/invoices/data/file-type.filter';
import { docCollFilterDefinition } from '@/src/app/modules/invoices/data/attached-document-type.filter';

const allStatuses: ESentInvoiceStatus[] = [
  ESentInvoiceStatus.ACCEPTED,
  ESentInvoiceStatus.DELIVERY_IMPOSSIBLE,
  ESentInvoiceStatus.EXPIRY_OF_TERMS,
  ESentInvoiceStatus.ISSUED_AND_DELIVERED,
  ESentInvoiceStatus.ISSUED_AND_NOT_CONS,
  ESentInvoiceStatus.PROCESSING_ERROR,
  ESentInvoiceStatus.REFUSED,
  ESentInvoiceStatus.REJECTED,
  ESentInvoiceStatus.SENT,
  ESentInvoiceStatus.TAKEN_IN_CHARGE,
  ESentInvoiceStatus.NON_ELECTRONIC_ISSUED,
  ESentInvoiceStatus.NON_ELECTRONIC_SENT,
  ESentInvoiceStatus.NON_ELECTRONIC_CANCELED,
];
export const sentInvoicesApiCallState: IApiCallState = {
  key: EApiCallStateKey.SENT_INVOICES,
  filtersUpdated: false,
  requestParts: [
    {
      key: EApiRequestPartKeys.PAGINATION,
      partName: EApiRequestPartsNames.PAGINATION,
      data: {
        page: 0,
        size: 10,
        totalItems: 0,
      },
    },
    {
      key: EApiRequestPartKeys.SORTING,
      partName: EApiRequestPartsNames.SORTING,
      data: {},
    },
    {
      key: EApiRequestPartKeys.FILTERS,
      partName: EApiRequestPartsNames.FILTER,
      data: {
        workedUsers: {
          id: 'workedUsers',
          property: 'workedUsers',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
        },
        fiscalYear: {
          id: 'fiscalYear',
          property: 'fiscalYear',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
        },
        date: {
          id: 'date',
          property: 'date',
          value: null,
          defaultValue: null,
          complexValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'date',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
          complexValueTemplate: {
            type: 'UgualeA',
            value: null,
          },
        },
        creationDate: {
          id: 'creationDate',
          property: 'creationDate',
          value: null,
          defaultValue: null,
          complexValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'date',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
          complexValueTemplate: {
            type: 'UgualeA',
            value: null,
          },
        },
        keyword: {
          id: 'keyword',
          property: 'keyword',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
        },
        number: {
          id: 'number',
          property: 'number',
          value: null,
          defaultValue: null,
          complexValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
          complexValueTemplate: {
            type: 'Contiene',
            value: '',
          },
        },
        receiverType: {
          id: 'receiverType',
          property: 'receiverType',
          value: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          doNotSendWhenDefault: true,
          ui: {
            default: {
              type: 'multiple',
              options: [
                {
                  label: marker('INVOICES.CLIENT_TYPE.FPR12'),
                  value: EClientType.PRIVATE,
                },
                {
                  label: marker('INVOICES.CLIENT_TYPE.FPA12'),
                  value: EClientType.PA,
                },
                {
                  label: marker('INVOICES.CLIENT_TYPE.FSM10'),
                  value: EClientType.SIMPLIFIED,
                },
              ],
            },
          },
          chips: {
            show: false,
          },
        },
        receiver: {
          id: 'receiver',
          property: 'receiver',
          value: null,
          defaultValue: null,
          complexValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
          complexValueTemplate: {
            type: 'Contiene',
            value: '',
          },
        },
        paymentStatus: {
          id: 'paymentStatus',
          property: 'collectionPaymentStatus',
          doNotSendWhenDefault: true,
          value: [
            ESentInvoicePaymentStatus.PAID,
            ESentInvoicePaymentStatus.UNPAID,
            ESentInvoicePaymentStatus.UNMANAGED,
            ESentInvoicePaymentStatus.PARTIALLY_PAID,
            ESentInvoicePaymentStatus.REVERSED,
          ],
          defaultValue: [
            ESentInvoicePaymentStatus.PAID,
            ESentInvoicePaymentStatus.UNPAID,
            ESentInvoicePaymentStatus.UNMANAGED,
            ESentInvoicePaymentStatus.PARTIALLY_PAID,
            ESentInvoicePaymentStatus.REVERSED,
          ],
          defaultValues: [
            [
              ESentInvoicePaymentStatus.PAID,
              ESentInvoicePaymentStatus.UNPAID,
              ESentInvoicePaymentStatus.UNMANAGED,
              ESentInvoicePaymentStatus.PARTIALLY_PAID,
              ESentInvoicePaymentStatus.REVERSED,
            ],
          ],
          comparator: SearchType.EQ,
          ui: {
            multiple: {
              type: 'multiple',
              options: [
                {
                  value: ESentInvoicePaymentStatus.PAID,
                  label: marker('SENT_INVOICES.PAYMENT_STATUS.PAID'),
                },
                {
                  value: ESentInvoicePaymentStatus.UNPAID,
                  label: marker('SENT_INVOICES.PAYMENT_STATUS.UNPAID'),
                },
                {
                  value: ESentInvoicePaymentStatus.UNMANAGED,
                  label: marker('SENT_INVOICES.PAYMENT_STATUS.UNMANAGED'),
                },
                {
                  value: ESentInvoicePaymentStatus.PARTIALLY_PAID,
                  label: marker('SENT_INVOICES.PAYMENT_STATUS.PARTIALLY_PAID'),
                },
                {
                  value: ESentInvoicePaymentStatus.REVERSED,
                  label: marker('SENT_INVOICES.PAYMENT_STATUS.REVERSED'),
                },
              ],
            },
          },
          chips: {
            show: false,
          },
        },
        fileNameUpload: {
          id: 'fileNameUpload',
          property: 'uploadFileName',
          value: null,
          complexValue: null,
          defaultValue: null,
          comparator: SearchType.EQ,
          ui: {
            default: {
              type: 'input',
            },
          },
          chips: {
            label: marker(''),
            show: false,
            type: 'value',
          },
          complexValueTemplate: {
            type: 'Contiene',
            value: '',
          },
        },
        fileType: clone(fileTypeFilterDefinition),
        docColl: clone(docCollFilterDefinition),
        status: {
          id: 'status',
          property: 'status',
          value: allStatuses,
          defaultValue: allStatuses,
          defaultValues: [allStatuses],
          comparator: SearchType.EQ,
          doNotSendWhenDefault: true,
          ui: {
            multiple: {
              type: 'multiple',
              options: [
                {
                  value: ESentInvoiceStatus.TAKEN_IN_CHARGE,
                  label: marker('SENT_INVOICES.STATUS.TAKEN_IN_CHARGE'),
                },
                {
                  value: ESentInvoiceStatus.PROCESSING_ERROR,
                  label: marker('SENT_INVOICES.STATUS.PROCESSING_ERROR'),
                },
                {
                  value: ESentInvoiceStatus.SENT,
                  label: marker('SENT_INVOICES.STATUS.SENT'),
                },
                {
                  value: ESentInvoiceStatus.REJECTED,
                  label: marker('SENT_INVOICES.STATUS.REJECTED'),
                },
                {
                  value: ESentInvoiceStatus.ISSUED_AND_NOT_CONS,
                  label: marker('SENT_INVOICES.STATUS.ISSUED_AND_NOT_CONS'),
                },
                {
                  value: ESentInvoiceStatus.DELIVERY_IMPOSSIBLE,
                  label: marker('SENT_INVOICES.STATUS.DELIVERY_IMPOSSIBLE'),
                },
                {
                  value: ESentInvoiceStatus.ISSUED_AND_DELIVERED,
                  label: marker('SENT_INVOICES.STATUS.ISSUED_AND_DELIVERED'),
                },
                {
                  value: ESentInvoiceStatus.ACCEPTED,
                  label: marker('SENT_INVOICES.STATUS.ACCEPTED'),
                },
                {
                  value: ESentInvoiceStatus.REFUSED,
                  label: marker('SENT_INVOICES.STATUS.REFUSED'),
                },
                {
                  value: ESentInvoiceStatus.EXPIRY_OF_TERMS,
                  label: marker('SENT_INVOICES.STATUS.EXPIRY_OF_TERMS'),
                },
                {
                  value: ESentInvoiceStatus.NON_ELECTRONIC_ISSUED,
                  label: marker('SENT_INVOICES.STATUS.NON_ELECTRONIC_ISSUED'),
                },
                {
                  value: ESentInvoiceStatus.NON_ELECTRONIC_SENT,
                  label: marker('SENT_INVOICES.STATUS.NON_ELECTRONIC_SENT'),
                },
                {
                  value: ESentInvoiceStatus.NON_ELECTRONIC_CANCELED,
                  label: marker('SENT_INVOICES.STATUS.NON_ELECTRONIC_CANCELED'),
                },
              ],
            },
          },
          chips: {
            label: '',
            show: false,
            type: 'options',
            options: [
              {
                value: ESentInvoiceStatus.TAKEN_IN_CHARGE,
                label: marker('SENT_INVOICES.STATUS.TAKEN_IN_CHARGE'),
              },
              {
                value: ESentInvoiceStatus.SENT,
                label: marker('SENT_INVOICES.STATUS.SENT'),
              },
              {
                value: ESentInvoiceStatus.REJECTED,
                label: marker('SENT_INVOICES.STATUS.REJECTED'),
              },
              {
                value: ESentInvoiceStatus.ISSUED_AND_NOT_CONS,
                label: marker('SENT_INVOICES.STATUS.ISSUED_AND_NOT_CONS'),
              },
              {
                value: ESentInvoiceStatus.DELIVERY_IMPOSSIBLE,
                label: marker('SENT_INVOICES.STATUS.DELIVERY_IMPOSSIBLE'),
              },
              {
                value: ESentInvoiceStatus.ISSUED_AND_DELIVERED,
                label: marker('SENT_INVOICES.STATUS.ISSUED_AND_DELIVERED'),
              },
              {
                value: ESentInvoiceStatus.ACCEPTED,
                label: marker('SENT_INVOICES.STATUS.ACCEPTED'),
              },
              {
                value: ESentInvoiceStatus.REFUSED,
                label: marker('SENT_INVOICES.STATUS.REFUSED'),
              },
              {
                value: ESentInvoiceStatus.EXPIRY_OF_TERMS,
                label: marker('SENT_INVOICES.STATUS.EXPIRY_OF_TERMS'),
              },
            ],
          },
        },
      } as IApiFiltersList,
    },
  ],
};
