import { Component, Inject, Injector } from '@angular/core';
import { SuperDialogComponent } from '../super-dialog/super-dialog.component';
import { DialogRef } from '@angular/cdk/dialog';
import { IDialogResult } from '@/src/app/core/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPasswordExpirationModalData } from './password-expiration-modal.interface';
import { FormControl } from '@angular/forms';
import { EDialogResultAction, EDialogCloseStatus } from '@/src/app/core/enums';

/**
 * PasswordExpirationModalComponent
 * @date 10/26/2023 - 2:03:50 PM
 *
 * @export
 * @class PasswordExpirationModalComponent
 * @typedef {PasswordExpirationModalComponent}
 * @extends {SuperDialogComponent<PasswordExpirationModalComponent>}
 */
@Component({
  selector: 'aup-password-expiration-modal',
  templateUrl: './password-expiration-modal.component.html',
  styleUrls: ['./password-expiration-modal.component.scss'],
  standalone: false,
})
export class PasswordExpirationModalComponent extends SuperDialogComponent<PasswordExpirationModalComponent> {
  /**
   * @inheritdoc
   * @date 10/26/2023 - 2:03:50 PM
   *
   * @type {string}
   */
  override content = 'default';

  /**
   * Sizes map for modal
   * @date 10/26/2023 - 2:03:50 PM
   *
   * @type {{ default: { small: { width: string; height: string; }; other: { width: string; height: string; }; }; }}
   */
  sizesMap = {
    default: {
      small: {
        width: '100vw',
        height: 'auto',
      },
      other: {
        width: '776px',
        height: 'auto',
      },
    },
  };

  /**
   * FormControl
   * @date 10/26/2023 - 2:03:50 PM
   *
   * @type {FormControl}
   */
  dontShowCheckbox: FormControl = new FormControl(false);

  /**
   * Creates an instance of PasswordExpirationModalComponent.
   * @date 10/26/2023 - 2:03:50 PM
   *
   * @constructor
   * @param {Injector} injector
   * @param {MatDialogRef<PasswordExpirationModalComponent, IDialogResult>} dialogRef
   * @param {IPasswordExpirationModalData} data
   */
  constructor(
    public override injector: Injector,
    public override dialogRef: MatDialogRef<PasswordExpirationModalComponent, IDialogResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: IPasswordExpirationModalData
  ) {
    super(injector, dialogRef);
  }

  /**
   * Change password handler
   * @date 10/26/2023 - 2:03:50 PM
   */
  onChangePassword() {
    window.location.href = this.data.accountManagementUrl + '/security/changepassword';
  }

  /**
   * @inheritdoc
   * @date 10/26/2023 - 2:03:50 PM
   */
  override onClose() {
    this.dialogRef.close({
      status: EDialogCloseStatus.OK,
      action: this.dontShowCheckbox.value
        ? EDialogResultAction.SKIP_PASSWORD_EXPIRED_REMINDER
        : EDialogResultAction.NONE,
    });
  }
}
