<div class="aup-background-gray px-4 md:pl-10 flex items-center justify-between">
  <h2 class="!p-0 w-2/3 md:w-auto" mat-dialog-title>{{ title | transloco }}</h2>
  <button type="button" (click)="close.emit()" mat-icon-button>
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<div class="p-6 flex items-center">
  <div class="mr-2"><mat-icon svgIcon="success"></mat-icon></div>
  <p>
    <ng-content></ng-content>
  </p>
</div>

<mat-divider></mat-divider>

<div class="flex !items-start !p-6" mat-dialog-actions>
  <button (click)="close.emit()" mat-stroked-button class="aup-btn-stroked-primary" cdkFocusInitial>
    {{ 'COMMON.CLOSE' | transloco }}
  </button>
</div>
