<form [formGroup]="formGroup" class="flex gap-2 flex-wrap w-full sm:flex-nowrap">
  <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'" class="w-[200px] sm:w-[150px]">
    <mat-select
      [panelWidth]="null"
      [placeholder]="'COMMON.PLACEHOLDER.SELECT' | transloco"
      formControlName="type"
    >
      <mat-option *ngFor="let comparator of dateNumberComparatorOptions" [value]="comparator.value">
        {{ comparator.text | transloco }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="formGroup?.value?.type">
    <ng-container *ngIf="formGroup?.value?.type !== eAdvancedFilterComparator.FROM_TO">
      <mat-form-field class="w-full" appearance="outline" [subscriptSizing]="'dynamic'" class="w-[150px]">
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="startDate"
          [min]="minDate"
          [max]="maxDate"
        />
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <mat-form-field
      *ngIf="formGroup?.value?.type === eAdvancedFilterComparator.FROM_TO"
      appearance="outline"
      [subscriptSizing]="'dynamic'"
      class="w-[250px]"
    >
      <mat-date-range-input [rangePicker]="rangePicker" [min]="minDate" [max]="maxDate">
        <input matStartDate formControlName="startDate" placeholder="gg/mm/aaaa" />
        <input matEndDate formControlName="endDate" placeholder="gg/mm/aaaa" />
      </mat-date-range-input>

      <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #rangePicker></mat-date-range-picker>
    </mat-form-field>
  </div>
</form>
