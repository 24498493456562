<div class="flex justify-between items-center gap-2 w-full">
  <div class="flex gap-2 flex-grow flex-wrap sm:flex-nowrap">
    <div class="w-[200px] min-w-[200px]">
      <form>
        <mat-form-field class="w-full" appearance="outline" [subscriptSizing]="'dynamic'">
          <mat-select [formControl]="filterSelect" [placeholder]="'COMMON.PLACEHOLDER.SELECT' | transloco">
            <mat-option *ngFor="let option of filtersOptions" [value]="option.property">
              {{ option.label | transloco }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="flex-grow" *ngIf="filterSelect.value">
      <ng-container [ngSwitch]="_config.filters[filterSelect.value].type">
        <aup-advanced-filters-date
          *ngSwitchCase="eAdvancedFilterType.DATE"
          [filterDefinition]="_config.filters[filterSelect.value]"
          [filterData]="filter"
          [apiCallStateKey]="_config.apiCallStateKey"
          [displayValidationErrors]="_displayValidationErrors"
          [fiscalYear]="_config.fiscalYear"
        ></aup-advanced-filters-date>
        <aup-advanced-filters-number
          *ngSwitchCase="eAdvancedFilterType.NUMBER"
          [filterDefinition]="_config.filters[filterSelect.value]"
          [filterData]="filter"
          [apiCallStateKey]="_config.apiCallStateKey"
          [displayValidationErrors]="_displayValidationErrors"
        ></aup-advanced-filters-number>
        <aup-advanced-filters-select
          *ngSwitchCase="eAdvancedFilterType.SELECT"
          [filterDefinition]="_config.filters[filterSelect.value]"
          [filterData]="filter"
          [apiCallStateKey]="_config.apiCallStateKey"
          [displayValidationErrors]="_displayValidationErrors"
        ></aup-advanced-filters-select>
        <aup-advanced-filters-status
          *ngSwitchCase="eAdvancedFilterType.STATUS"
          [filterDefinition]="_config.filters[filterSelect.value]"
          [filterData]="filter"
          [apiCallStateKey]="_config.apiCallStateKey"
          [displayValidationErrors]="_displayValidationErrors"
        ></aup-advanced-filters-status>
        <aup-advanced-filters-text
          *ngSwitchCase="eAdvancedFilterType.TEXT"
          [filterDefinition]="_config.filters[filterSelect.value]"
          [filterData]="filter"
          [apiCallStateKey]="_config.apiCallStateKey"
          [displayValidationErrors]="_displayValidationErrors"
        ></aup-advanced-filters-text>
      </ng-container>
    </div>
  </div>
  <div class="w-[50px]">
    <button mat-icon-button [class.!hidden]="isLast && !filterSelect.value" (click)="onFilterDelete()">
      <mat-icon svgIcon="delete"></mat-icon>
    </button>
  </div>
</div>
