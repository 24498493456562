export enum EApiCallStateKey {
  FEL = 'FEL',
  NEWS = 'NEWS',
  PEC = 'PEC',
  RECEIVED_INVOICES = 'RECEIVED_INVOICES',
  SENT_INVOICES = 'SENT_INVOICES',
}

export enum EApiRequestPartKeys {
  SEARCH = 'SEARCH',
  PAGINATION = 'PAGINATION',
  SORTING = 'SORTING',
  FILTERS = 'FILTERS',
}

export enum EApiRequestPartsNames {
  HEADERS = 'headers',
  QUERY_PARAMS = 'queryParams',
  ACTION_PARAMS = 'actionParams',
  QUERY = 'query',
  SEARCH = 'search',
  FILTER = 'filter',
  EMBED = 'embed',
  COMPUTED_FIELDS = 'computedFields',
  SORTING = 'sorting',
  DATA = 'data',
  PAGINATION = 'pagination',
}
