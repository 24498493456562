import { Component, Input } from '@angular/core';

@Component({
  selector: 'aup-circle-progress-bar',
  templateUrl: './circle-progress-bar.component.html',
  styleUrls: ['./circle-progress-bar.component.scss'],
  standalone: false,
})
export class CircleProgressBarComponent {
  @Input() progress: number = 0; // progress input from 0 to 100

  constructor() {}

  ngOnInit(): void {}

  getStrokeDashArray(): string {
    const circleCircumference = 2 * Math.PI * 18; // Adjusted radius to 18 to fit properly
    const dashArray = (this.progress / 100) * circleCircumference;
    return `${dashArray} ${circleCircumference}`;
  }
}
