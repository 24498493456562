<form [formGroup]="formGroup" class="flex gap-2 w-full flex-wrap sm:flex-nowrap">
  <div class="w-[200px] sm:w-[150px]">
    <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'" class="w-full">
      <mat-select
        [panelWidth]="null"
        [placeholder]="'COMMON.PLACEHOLDER.SELECT' | transloco"
        formControlName="type"
      >
        <mat-option *ngFor="let comparator of dateNumberComparatorOptions" [value]="comparator.value">
          {{ comparator.text | transloco }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="flex-grow flex gap-2" *ngIf="formGroup?.value?.type">
    <ng-container *ngIf="formGroup?.value?.type !== eAdvancedFilterComparator.FROM_TO; else rangeInputs">
      <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'" class="w-full">
        <input
          matInput
          formControlName="value"
          type="number"
          [placeholder]="'ADVANCED_FILTERS.PLACEHOLDER.SEARCH' | transloco"
        />
      </mat-form-field>
    </ng-container>
  </div>

  <ng-template #rangeInputs>
    <div class="w-full">
      <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'" class="w-1/2 pr-1">
        <input
          matInput
          formControlName="valueFrom"
          type="number"
          [placeholder]="'ADVANCED_FILTERS.PLACEHOLDER.FROM' | transloco"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'" class="w-1/2 pl-1">
        <input
          matInput
          formControlName="valueTo"
          type="number"
          [placeholder]="'ADVANCED_FILTERS.PLACEHOLDER.TO' | transloco"
        />
      </mat-form-field>
    </div>
  </ng-template>
</form>
