import { SuperModel } from './super-model.model';

export class ReportMail extends SuperModel {
  enabledReport: boolean;
  intervalStart: string | null;
  intervalEnd: string | null;
  emailAddress: string | null;

  constructor(data: any) {
    super(data);
  }

  override get serialised() {
    return {
      enabledReport: this.enabledReport,
      intervalStart: this.intervalStart,
      intervalEnd: this.intervalEnd,
      emailAddress: this.emailAddress,
    };
  }
}
