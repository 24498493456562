import { getObjValue, timeDiff } from '../lib/utils.js';

/**
 * greater check for data
 *
 * @param {*object} searchCondition: { name, value }
 * @param {*boolean} caseSensitive: false
 * @param {*DataObject} data
 */
var greater = function (_a, caseSensitive, data) {
  var key = _a.key,
    value = _a.value;
  var targetValue = getObjValue(data, key);
  if (targetValue == null) {
    return false;
  }
  // disable like search if targetValue is array
  if (Array.isArray(targetValue)) {
    return false;
  }
  // 数字の場合
  if (typeof targetValue === 'number') {
    return targetValue > Number(value);
  }
  // value is date
  if (value instanceof Date) {
    return timeDiff(value, targetValue) < 0;
  }
  if (caseSensitive) {
    return targetValue > value;
  }
  return targetValue.toString().toUpperCase() > value.toString().toUpperCase();
};
export { greater as default };
