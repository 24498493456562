<ng-container *ngIf="languagesService.isTranslationsLoaded">
  <ng-container *ngIf="filter$ | async as filter">
    <mat-form-field *ngIf="formControl" class="w-full" appearance="outline" [subscriptSizing]="'dynamic'">
      <mat-select #matSelect [formControl]="formControl" multiple="true">
        <aup-select-check-all
          [text]="'COMMON.SELECT_ALL' | transloco"
          [values]="filter.ui[uiKey].options"
          [model]="formControl"
        ></aup-select-check-all>
        <mat-option *ngFor="let option of filter.ui[uiKey].options" [value]="option.value">
          <aup-status-chip [statusesMap]="statusesMap" [status]="option.value.toString()"></aup-status-chip>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</ng-container>
