export enum EReceivedInvoiceAction {
  PRINT = 'PRINT',
  MARK_AS_READ = 'MARK_AS_READ',
  MARK_AS_UNREAD = 'MARK_AS_UNREAD',
  DOWNLOAD_PDF = 'DOWNLOAD_PDF',
  VIEW_SDI = 'VIEW_SDI',
  DOWNLOAD_XML = 'DOWNLOAD_XML',
  DOWNLOAD_SAVED_FILES = 'DOWNLOAD_SAVED_FILES',
  DOWNLOAD_P7M = 'DOWNLOAD_P7M',
}

export enum EReceivedInvoiceStatus {
  READ = '4', //Letta
  NOT_READ = '2', //Non letta
}

export enum EReceivedInvoicePaymentStatus {
  PAID = 1, //pagata
  UNPAID = 0, //non pagata
  UNMANAGED = 2, //Non gestita,
  PARTIALLY_PAID = 3, //Parzialmnente incassata
  REVERSED = 4, //Stornata
}

export enum EReceivedInvoiceBulkAction {
  REPORT = 'REPORT',
  DOWNLOAD_PDF = 'DOWNLOAD_PDF',
  DOWNLOAD_XML = 'DOWNLOAD_XML',
  DOWNLOAD_P7M = 'DOWNLOAD_P7M',
  DOWNLOAD_NOTIFY = 'DOWNLOAD_NOTIFY',
  DOWNLOAD_PDA = 'DOWNLOAD_PDA',
  MARK_AS_READ = 'MARK_AS_READ',
  MARK_AS_UNREAD = 'MARK_AS_UNREAD',
}
