<div
  *ngIf="isOpened && job"
  class="fixed bottom-0 w-full flex flex-col p-2 pl-4 rounded-lg bg-black z-50 md:w-[296px] md:bottom-6 md:right-6"
>
  <div class="flex justify-end">
    <!-- <button class="p-1">
      <mat-icon svgIcon="minus"
        class="!w-3 !h-3"></mat-icon>
    </button> -->
    <button class="p-1" (click)="onClose()">
      <mat-icon svgIcon="close_white" class="!w-3 !h-3"></mat-icon>
    </button>
  </div>
  <div class="flex items-center justify-start pb-3 gap-2">
    <ng-container
      *ngIf="[eBillingJobStatus.SUBMITTED, eBillingJobStatus.CREATED].includes(job?.executionStatus)"
    >
      <mat-icon svgIcon="queue"></mat-icon>
      <p class="text-white text-base">{{ 'BILLING_JOB_PROGRESS.QUEUE' | transloco }}</p>
    </ng-container>

    <ng-container *ngIf="job?.executionStatus === eBillingJobStatus.RUNNING">
      <aup-circle-progress-bar [progress]="job?.resultProgress"></aup-circle-progress-bar>
      <p class="text-white text-base">
        {{ 'BILLING_JOB_PROGRESS.PACKAGE_CREATION_IN_PROGRESS' | transloco }}
      </p>
    </ng-container>

    <ng-container
      *ngIf="
        [eBillingJobStatus.ERROR, eBillingJobStatus.ABORTED, eBillingJobStatus.CANCELLED_BY_USER].includes(
          job?.executionStatus
        )
      "
    >
      <mat-icon svgIcon="error"></mat-icon>
      <p class="text-white text-base">
        {{ 'BILLING_JOB_PROGRESS.ERROR' | transloco }} {{ job?.errorMessage }}
      </p>
    </ng-container>

    <ng-container *ngIf="[eBillingJobStatus.SUCCESS].includes(job?.executionStatus)">
      <mat-icon svgIcon="success"></mat-icon>
      <p class="text-white text-base">
        {{ 'BILLING_JOB_PROGRESS.SUCCESS_MESSAGE' | transloco }}
      </p>
    </ng-container>
  </div>
</div>
