import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MATERIAL_MODULES, CDK_MODULES, ARU_DATE_FORMATS } from './material-modules';
import {
  MAT_DATE_FORMATS,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
  DateAdapter,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RecaptchaModule } from 'ng-recaptcha-2';

import { TranslocoRootModule } from './transloco/transloco-root.module';

import { InfinitiveScrollTriggerComponent } from './components/infinitive-scroll-trigger/infinitive-scroll-trigger.component';
import { NewsChipComponent } from './components/news-chip/news-chip.component';
import { NotificationBarComponent } from './components/notification-bar/notification-bar.component';
import { MasterDetailComponent } from './components/master-detail/master-detail.component';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { NewsListDetailComponent } from '../modules/news/news-list-detail/news-list-detail.component';
import { NewsListComponent } from '../modules/news/news-list/news-list.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { FilterComponent } from './components/filter/filter.component';
import { FiltersChipsComponent } from './components/filters-chips/filters-chips.component';
import { FiltersResetButtonComponent } from './components/filters-reset-button/filters-reset-button.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import { InlineLoaderComponent } from './components/inline-loader/inline-loader.component';
import { StatusFilterComponent } from './components/status-filter/status-filter.component';
import { TableStatusComponent } from './components/table-status/table-status.component';
import { LoaderComponent } from './components/loader/loader.component';
import { OkCancelDialogComponent } from './components/ok-cancel-dialog/ok-cancel-dialog.component';
import { UsageIndicatorComponent } from './components/usage-indicator/usage-indicator.component';
import { ConfirmationMessageModalComponent } from './components/confirmation-message-modal/confirmation-message-modal.component';
import { ListFiltersComponent } from './components/list-filters/list-filters.component';
import { PasswordExpirationModalComponent } from './components/password-expiration-modal/password-expiration-modal.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { SelectCheckAllComponent } from './components/select-check-all/select-check-all.component';
import { UnsavedChangesProtectionModalComponent } from './components/unsaved-changes-protection-modal/unsaved-changes-protection-modal.component';
import { ItemSelectorComponent } from './components/item-selector/item-selector.component';
import { JobProgressComponent } from './components/job-progress/job-progress.component';
import { CircleProgressBarComponent } from './components/circle-progress-bar/circle-progress-bar.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ColumnsManagerComponent } from './components/columns-manager/columns-manager.component';
import { QuickSortComponent } from './components/quick-sort/quick-sort.component';
import { AdvancedFiltersComponent } from './components/advanced-filters/advanced-filters.component';
import { AdvancedFiltersMainComponent } from './components/advanced-filters/components/advanced-filters-main/advanced-filters-main.component';
import { AdvancedFiltersNumberComponent } from './components/advanced-filters/components/advanced-filters-number/advanced-filters-number.component';
import { AdvancedFiltersDateComponent } from './components/advanced-filters/components/advanced-filters-date/advanced-filters-date.component';
import { AdvancedFiltersTextComponent } from './components/advanced-filters/components/advanced-filters-text/advanced-filters-text.component';
import { AdvancedFiltersSelectComponent } from './components/advanced-filters/components/advanced-filters-select/advanced-filters-select.component';
import { AdvancedFiltersStatusComponent } from './components/advanced-filters/components/advanced-filters-status/advanced-filters-status.component';
import { AlertActionDialogComponent } from './components/alert-action-dialog/alert-action-dialog.component';
import { PermittedInvoiceActionsDirective } from './directives/permitted-invoice-actions.directive';
import { MatRangeValidationFixDirective } from './directives/mat-date-range-validation-fix.directive';
import { IsNonElectronicPipe } from './pipe/is-non-electronic.pipe';
import moment from 'moment';
import 'moment/locale/it';

moment.locale('it');

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

const SHARED_MODULES = [
  RouterModule,
  CommonModule,
  TranslocoRootModule,
  ReactiveFormsModule,
  RecaptchaModule,
  CDK_MODULES,
  MATERIAL_MODULES,
];
const SHARED_COMPONENTS = [
  InfinitiveScrollTriggerComponent,
  NewsChipComponent,
  NotificationBarComponent,
  MasterDetailComponent,
  NewsListComponent,
  NewsListDetailComponent,
  PaginatorComponent,
  FilterComponent,
  FiltersChipsComponent,
  FiltersResetButtonComponent,
  StatusChipComponent,
  InlineLoaderComponent,
  StatusFilterComponent,
  TableStatusComponent,
  LoaderComponent,
  OkCancelDialogComponent,
  UsageIndicatorComponent,
  ConfirmationMessageModalComponent,
  ListFiltersComponent,
  PasswordExpirationModalComponent,
  ErrorMessageComponent,
  ToastMessageComponent,
  RecaptchaComponent,
  SelectCheckAllComponent,
  UnsavedChangesProtectionModalComponent,
  ItemSelectorComponent,
  JobProgressComponent,
  CircleProgressBarComponent,
  BadgeComponent,
  ColumnsManagerComponent,
  QuickSortComponent,
  AdvancedFiltersComponent,
  AdvancedFiltersMainComponent,
  AdvancedFiltersNumberComponent,
  AdvancedFiltersDateComponent,
  AdvancedFiltersTextComponent,
  AdvancedFiltersSelectComponent,
  AdvancedFiltersStatusComponent,
  AlertActionDialogComponent,
];
const SHARED_DIRECTIVES = [PermittedInvoiceActionsDirective, MatRangeValidationFixDirective];
const SHARED_PIPES = [IsNonElectronicPipe];
const PROVIDERS = [
  { provide: DateAdapter, useClass: MomentDateAdapter },
  { provide: MAT_DATE_FORMATS, useValue: ARU_DATE_FORMATS },
  { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
  { provide: MAT_DATE_LOCALE, useValue: 'it' },
  MatPaginatorIntl,
  provideTranslocoMessageformat(),
  IsNonElectronicPipe,
];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
