import { Component, Inject, Injector } from '@angular/core';
import { SuperDialogComponent } from '../super-dialog/super-dialog.component';
import { IDialogSizeMap, IOkCancelDialogData } from '@interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EDialogCloseStatus } from '@enums';

@Component({
  selector: 'aup-ok-cancel-dialog',
  templateUrl: './ok-cancel-dialog.component.html',
  styleUrls: ['./ok-cancel-dialog.component.scss'],
  standalone: false,
})
export class OkCancelDialogComponent extends SuperDialogComponent<OkCancelDialogComponent> {
  EDialogCloseStatus = EDialogCloseStatus;
  override content: string = 'default';
  /**
   * Dialog sizing map depend on content/screen size
   * @date 10/10/2023 - 8:09:37 PM
   *
   * @type {IDialogSizeMap}
   */
  sizesMap: IDialogSizeMap = {
    default: {
      small: {
        width: '100vw',
        height: '100vh',
      },
      other: {
        width: '500px',
        height: 'auto',
      },
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IOkCancelDialogData,
    override injector: Injector,
    override dialogRef: MatDialogRef<OkCancelDialogComponent>
  ) {
    super(injector, dialogRef);
  }
}
