import targetValueArray from '../prefilters/targetValueArray.js';
import greater from './greater.js';
import complement from '../node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/complement.js';

/**
 * less or equal check for data
 *
 * @param {*object} searchCondition: { name, value }
 * @param {*boolean} caseSensitive: false
 * @param {*DataObject} data
 */
var lessOrEqual = targetValueArray(complement(greater));
export { lessOrEqual as default };
