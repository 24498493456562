import targetValueArray from '../prefilters/targetValueArray.js';
import less from './less.js';
import complement from '../node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/complement.js';

/**
 * greater or equal check for data
 *
 * @param {*object} searchCondition: { name, value }
 * @param {*boolean} caseSensitive: false
 * @param {*DataObject} data
 */
var greaterOrEqual = targetValueArray(complement(less));
export { greaterOrEqual as default };
