import SearchType from '../lib/SearchType.js';
import equal from './equal.js';
import greater from './greater.js';
import greaterOrEqual from './greaterOrEqual.js';
import less from './less.js';
import lessOrEqual from './lessOrEqual.js';
import like from './like.js';
import notEqual from './notEqual.js';
import notLike from './notLike.js';
var _a;
var filtersMap = (_a = {}, _a[SearchType.EQ] = equal, _a[SearchType.GT] = greater, _a[SearchType.GTE] = greaterOrEqual, _a[SearchType.LT] = less, _a[SearchType.LTE] = lessOrEqual, _a[SearchType.LK] = like, _a[SearchType.NE] = notEqual, _a[SearchType.NLK] = notLike, _a);
export { filtersMap as default };
