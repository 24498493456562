import { Injectable, Injector } from '@angular/core';
import { ApiService } from './api.service';
import { News } from '@models';
import { Observable, delay, map, of } from 'rxjs';
import { IApiRequest, IApiResponse } from '../../interfaces';
import { tempNewsResponseMock } from './news-mock';

@Injectable({
  providedIn: 'root',
})
export class NewsService extends ApiService {
  private DISPLAYED_SUPER_NEWS_IDS = 'DISPLAYED_SUPER_NEWS_IDS';

  constructor(injector: Injector) {
    super('news', News, injector);
  }

  override patch<T>(requestParams?: IApiRequest): Observable<IApiResponse<T>> {
    return of({
      data: requestParams?.body,
    } as IApiResponse<T>).pipe(delay(100));
  }

  override get<News>(requestParams?: IApiRequest): Observable<IApiResponse<News[]>> {
    return super.get({
      queryParams: {
        distributionChannelId: 1,
        languageCode: this.translocoService.getActiveLang()?.toUpperCase(),
      },
      ...requestParams,
    });

    // NOTE: this is code used to test news components without api,
    // will be removed at the end of news module in future PR
    // return of(tempNewsResponseMock).pipe(
    //   map((envelope: IApiResponse<any>) => {
    //     const response = {
    //       ...envelope,
    //       data: (envelope.data as News[]).map(e => new this.entityClass(e)) as News[],
    //     };

    //     //response.data = response.data.slice(0, 3);
    //     return response;
    //   })
    // );
  }

  getSuperNews() {
    return this.request<News[]>({
      method: 'GET',
      path: '/supernews',
      queryParams: {
        distributionChannelId: 1,
        languageCode: this.translocoService.getActiveLang()?.toUpperCase(),
      },
    }).pipe(
      map(response => {
        if (!response.data[0]) {
          return response;
        }
        const displayedNewsIds = this.getDisplayedSuperNewsIds();
        response.data = response.data.filter(news => !displayedNewsIds.includes(news.id));
        if (response.data[0]) {
          this.addDisplayedSuperNewsIds(response.data[0].id);
        }
        return response;
      })
    );
  }

  resetDisplayedSuperNewsIds() {
    localStorage.setItem(this.DISPLAYED_SUPER_NEWS_IDS, JSON.stringify([]));
  }

  private getDisplayedSuperNewsIds(): string[] {
    return JSON.parse(localStorage.getItem(this.DISPLAYED_SUPER_NEWS_IDS) || '[]') || [];
  }

  private addDisplayedSuperNewsIds(id: string) {
    const displayedSuperNews: string[] = this.getDisplayedSuperNewsIds();
    displayedSuperNews.push(id);

    localStorage.setItem(this.DISPLAYED_SUPER_NEWS_IDS, JSON.stringify(displayedSuperNews));
  }
}
