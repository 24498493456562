import { ENewsCategory } from '@/src/app/core/enums';
import { News } from '@/src/app/core/models';
import { Component, Input } from '@angular/core';
import { marker } from '@jsverse/transloco-keys-manager/marker';

/**
 * NewsChipComponent
 *
 * @export
 * @class NewsChipComponent
 * @typedef {NewsChipComponent}
 */
@Component({
  selector: 'aup-news-chip',
  templateUrl: './news-chip.component.html',
  styleUrls: ['./news-chip.component.scss'],
  standalone: false,
})
export class NewsChipComponent {
  eNewsCategory = ENewsCategory;
  /**
   * Category
   *
   * @type {News}
   */
  @Input() news: News;

  /**
   * News category
   *
   * @type {*}
   */
  private translations = [
    marker('NEWS.CATEGORY.FEL.1'),
    marker('NEWS.CATEGORY.FEL.2'),
    marker('NEWS.CATEGORY.FEL.3'),
    marker('NEWS.CATEGORY.FEL.4'),
  ];
}
