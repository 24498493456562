import targetValueArray from '../prefilters/targetValueArray.js';
import like from './like.js';
import complement from '../node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/complement.js';

/**
 * not like check for data
 *
 * @param {*object} searchCondition: { name, value }
 * @param {*boolean} caseSensitive: false
 * @param {*DataObject} data
 */
var notLike = targetValueArray(complement(like));
export { notLike as default };
