export enum EAdvancedFilterType {
  DATE = 'date',
  NUMBER = 'number',
  SELECT = 'select',
  STATUS = 'status',
  TEXT = 'text',
}

export enum EAdvancedFilterComparator {
  CONTAINS = 'Contiene',
  DOES_NOT_CONTAIN = 'NonContiene',
  EQUALS = 'UgualeA',
  GREATER_THAN = 'MaggioreDi',
  LESS_THAN = 'MinoreDi',
  EQUAL_TO = 'UgualeA',
  FROM_TO = 'DaA',
}
