import { getObjValue, timeDiff } from '../lib/utils.js';

/**
 * equal check for data
 *
 * @param {*object} searchCondition: { name, value }
 * @param {*boolean} caseSensitive: false
 * @param {*DataObject} data
 */
var equal = function (_a, caseSensitive, data) {
  var key = _a.key,
    value = _a.value;
  var targetValue = getObjValue(data, key);
  if (targetValue == null) {
    return false;
  }
  if (typeof targetValue === 'number') {
    return targetValue === Number(value);
  }
  // value is date
  if (value instanceof Date) {
    return Array.isArray(targetValue) ? targetValue.some(function (x) {
      return timeDiff(value, x) === 0;
    }) : timeDiff(value, targetValue) === 0;
  }
  if (Array.isArray(targetValue)) {
    if (caseSensitive) {
      return targetValue.includes(value.toString());
    }
    return targetValue.map(function (x) {
      return x.toString().toUpperCase();
    }).includes(value.toString().toUpperCase());
  }
  if (caseSensitive) {
    return targetValue === value.toString();
  }
  return targetValue.toString().toUpperCase() === value.toString().toUpperCase();
};
export { equal as default };
