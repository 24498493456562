import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * TableStatusComponent
 *
 * @export
 * @class TableStatusComponent
 * @typedef {TableStatusComponent}
 */
@Component({
  selector: 'aup-table-status',
  templateUrl: './table-status.component.html',
  styleUrls: ['./table-status.component.scss'],
  standalone: false,
})
export class TableStatusComponent {
  @Input() items: any[];
  @Input() noItemsText: string;
  @Input() isLoading$: Observable<boolean>;
  @Input() ctaText: string;
  @Output() ctaClick = new EventEmitter();

  constructor() {}

  onCtaClick() {
    this.ctaClick.emit();
  }
}
