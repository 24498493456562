import path from '../node_modules/.pnpm/ramda@0.29.0/node_modules/ramda/es/path.js';

// use impure combiner to speed up
function listCombiner(list, val) {
  list.push(val);
  return list;
}
// use self-made compose to avoid typescript error
function compose() {
  var fns = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    fns[_i] = arguments[_i];
  }
  return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return fns.reduceRight(function (prevBC, fn) {
      if (!Array.isArray(prevBC)) {
        // eslint-disable-next-line no-param-reassign
        prevBC = [prevBC];
      }
      return fn.apply(void 0, prevBC);
    }, args);
  };
}
function getObjValue(data, key) {
  return path(typeof key === 'string' ? key.split('.') : key, data);
}
function timeDiff(value, targetValue) {
  return value.getTime() - new Date(targetValue.toString()).getTime();
}
export { compose, getObjValue, listCombiner, timeDiff };
