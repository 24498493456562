import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IToastData } from '../interfaces';
import { TranslocoService } from '@jsverse/transloco';
import { ToastMessageComponent } from '@shared/components/toast-message/toast-message.component';
import { EventType, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToastMessagesService {
  constructor(
    protected matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private router: Router
  ) {
    this.listenForNavigationChangeAndDismiss();
  }

  listenForNavigationChangeAndDismiss() {
    this.router.events
      .pipe(
        filter(event => event.type === EventType.NavigationStart),
        tap(() => this.matSnackBar.dismiss())
      )
      .subscribe();
  }

  show(data: IToastData) {
    data.title = data.title && this.translocoService.translate(data.title);
    data.message = data.message && this.translocoService.translate(data.message);

    const options: MatSnackBarConfig<IToastData> = {
      duration: data.duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: `aup-toast-message-${data.type}`,
      data,
    };

    this.matSnackBar.openFromComponent(ToastMessageComponent, options);
  }

  dismiss() {
    this.matSnackBar.dismiss();
  }
}
