import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function dateRangeValidator(minDate: string, maxDate: string): ValidatorFn {
  const _minDate = moment(minDate).utc().toDate();
  const _maxDate = moment(maxDate).utc().toDate();
  setHours(_minDate, 'start');
  setHours(_maxDate, 'end');
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const date = moment(value).toDate();

    if (date.getTime() < _minDate.getTime() || date.getTime() > _maxDate.getTime()) {
      return { dateRange: 'invalid' };
    }
    return null;
  };
}

function setHours(date: Date, type: 'start' | 'end') {
  if (type === 'start') {
    date.setHours(0, 0, 0, 0);
  } else {
    date.setHours(23, 59, 59, 999);
  }
}
