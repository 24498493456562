export enum ESentInvoiceAction {
  PRINT = 'PRINT',
  DOWNLOAD_XML = 'DOWNLOAD_XML',
  DOWNLOAD_PDF = 'DOWNLOAD_PDF',
  VIEW_SDI = 'VIEW_SDI',
  DOWNLOAD_SAVED_FILES = 'DOWNLOAD_SAVED_FILES',
  DOWNLOAD_P7M = 'DOWNLOAD_P7M',
  DOWNLOAD_NOTIFICATIONS = 'DOWNLOAD_NOTIFICATIONS',
}

export enum ESentInvoiceStatus {
  TAKEN_IN_CHARGE = '1', //Presa in carico
  PROCESSING_ERROR = '2', //Errore elaborazione
  SENT = '3', //Inviata
  REJECTED = '4', //Scartata
  ISSUED_AND_NOT_CONS = '5', //Emessa e Non cons
  DELIVERY_IMPOSSIBLE = '6', //Recapito impossibile
  ISSUED_AND_DELIVERED = '7', //Emessa e consegnata
  ACCEPTED = '8', //Accettata
  REFUSED = '9', //Rifiutata
  EXPIRY_OF_TERMS = '10', //Decorrenza termini
  NON_ELECTRONIC_ISSUED = '51',
  NON_ELECTRONIC_SENT = '52',
  NON_ELECTRONIC_CANCELED = '53',
}

export enum ESentInvoicePaymentStatus {
  PAID = 1, //pagata
  UNPAID = 0, //non pagata
  UNMANAGED = 2, //Non gestita
  PARTIALLY_PAID = 3, //Parzialmnente incassata
  REVERSED = 4, //Stornata
}

export enum ESentInvoiceBulkAction {
  REPORT = 'REPORT',
  DOWNLOAD_PDF = 'DOWNLOAD_PDF',
  DOWNLOAD_XML = 'DOWNLOAD_XML',
  DOWNLOAD_P7M = 'DOWNLOAD_P7M',
  DOWNLOAD_NOTIFY = 'DOWNLOAD_NOTIFY',
  DOWNLOAD_PDA = 'DOWNLOAD_PDA',
}
