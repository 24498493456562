<form [formGroup]="formGroup" class="flex gap-2 flex-wrap sm:flex-nowrap">
  <ng-container *ngIf="languagesService.isTranslationsLoaded">
    <div
      *ngIf="filterDefinition?.options?.length; else multipleGroupsTemplate"
      class="w-[200px] sm:w-[150px]"
    >
      <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'">
        <mat-select
          formControlName="values"
          multiple="true"
          [panelWidth]="null"
          [placeholder]="'COMMON.PLACEHOLDER.SELECT' | transloco"
        >
          <aup-select-check-all
            [text]="'COMMON.SELECT_ALL' | transloco"
            [textWhenSelectedAll]="'COMMON.UNSELECT_ALL' | transloco"
            [values]="filterDefinition.options"
            [model]="formControl"
          ></aup-select-check-all>
          <mat-option *ngFor="let option of filterDefinition.options" [value]="option.value">
            {{ option.label | transloco }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-template #multipleGroupsTemplate>
    <mat-form-field
      *ngIf="filterDefinition?.optionsGroups?.length"
      class="w-[150px] min-w-[150px]"
      appearance="outline"
      [subscriptSizing]="'dynamic'"
    >
      <mat-select
        formControlName="values"
        multiple="true"
        [panelWidth]="null"
        [placeholder]="'COMMON.PLACEHOLDER.SELECT' | transloco"
      >
        <aup-select-check-all
          [text]="'COMMON.SELECT_ALL' | transloco"
          [textWhenSelectedAll]="'COMMON.UNSELECT_ALL' | transloco"
          [values]="getAllOptions(filterDefinition.optionsGroups)"
          [model]="formControl"
        ></aup-select-check-all>
        <mat-optgroup *ngFor="let group of filterDefinition.optionsGroups" [label]="group.label | transloco">
          <mat-option *ngFor="let option of group.options" [value]="option.value">
            {{ option.label | transloco }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </ng-template>
</form>
