<div class="flex items-center">
  <div
    class="rounded-full px-2 flex items-center justify-center whitespace-nowrap"
    [style.backgroundColor]="config.backgroundColor"
    [style.color]="config.textColor"
    [style.font-size]="config.fontSize || '10px'"
    [matTooltip]="config.tooltipText | transloco"
  >
    {{ config.text | transloco }}
  </div>
</div>
