import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDialogResult, IDialogSize, IDialogSizeMap } from '@interfaces';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectBreakpoints } from '@core/state/session/session.selectors';
import { EDialogCloseStatus } from '@/src/app/core/enums';
import { IAlertActionDialogInterface } from '@core/interfaces/i-alert-action-dialog.interface';

@Component({
  selector: 'aup-alert-action-dialog',
  templateUrl: './alert-action-dialog.component.html',
  styleUrls: ['./alert-action-dialog.component.scss'],
  standalone: false,
})
export class AlertActionDialogComponent implements OnDestroy {
  protected EDialogCloseStatus = EDialogCloseStatus;
  private sizesMap: IDialogSizeMap = {
    default: {
      small: {
        width: '100vw',
        height: '100vh',
      },
      other: {
        width: '850px',
        height: 'auto',
      },
    },
  };

  private subscription: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: IAlertActionDialogInterface,
    protected dialogRef: MatDialogRef<AlertActionDialogComponent, IDialogResult>,
    private store$: Store<Store>
  ) {
    this.listenBreakpointsSizeForChange();
  }

  private listenBreakpointsSizeForChange() {
    const breakpoints$ = this.store$.select(selectBreakpoints).subscribe(breakpoints => {
      const isSmall = breakpoints.isSmall || breakpoints.isXSmall;
      const size: IDialogSize = isSmall ? this.sizesMap['default'].small : this.sizesMap['default'].other;

      this.dialogRef.updateSize(size.width, size.height);
      this.dialogRef.updatePosition();
    });

    this.subscription.add(breakpoints$);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
