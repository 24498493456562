export enum EPecStatus {
  DELEGATED = 'DELEGATED', // delegato,
  WAITING = 'WAITING', // in attesa /
  TO_ACCEPT = 'TO_ACCEPT', // da accettare
  SUSPENDED = 'SUSPENDED', // sospeso
  REFUSED = 'REFUSED', // rifiutato
  REVOKED = 'REVOKED', // revocato
  SUSPENDED_UPGRADE_REQUIRED = 'SUSPENDED_UPGRADE_REQUIRED',
}

export enum EAutomaticRenewalStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EPecPermissionStatus {
  UNCHANGED = 'UNCHANGED',
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
}

export enum EPecComponentAction {
  INVITE_USER = 'INVITE_USER',
  GO_TO_SECOND_STEP = 'GO_TO_SECOND_STEP',
  CANCEL = 'CANCEL',
  SUBMIT_DATA = 'SUBMIT_DATA',
}

export enum EPecAction {
  MANAGE = 'MANAGE',
  REVOKE = 'REVOKE',
  MODIFY = 'MODIFY',
  ACCESS = 'ACCESS',
  NEW = 'NEW',
  HIDE = 'HIDE',
}

export enum EPecDelegationModalContent {
  FIRST_STEP = 'FIRST_STEP',
  SECOND_STEP = 'SECOND_STEP',
  USER_INVITED_CONFIRMATION = 'USER_INVITED_CONFIRMATION',
  DATA_SUBMIT_CONFIRMATION = 'DATA_SUBMIT_CONFIRMATION',
}

export enum EPecCos {
  PRO = 'PRO',
  PREMIUM = 'PREMIUM',
  STANDARD = 'STANDARD',
}
