<div class="flex items-center justify-center">
  <svg width="48" height="48" viewBox="0 0 48 48">
    <circle
      cx="24"
      cy="24"
      r="18"
      fill="none"
      stroke="#87D97A"
      stroke-width="2"
      [attr.stroke-dasharray]="getStrokeDashArray()"
      stroke-dashoffset="0"
      transform="rotate(-90 24 24)"
    />
  </svg>
  <div class="absolute">
    <span class="text-sm leading-[14px] text-green-400">{{ progress }}%</span>
  </div>
</div>
