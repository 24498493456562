import { Component } from '@angular/core';
import { AdvancedFiltersFilterItemBase } from '../advanced-filters-filter-item-base/advanced-filters-filter-item-base.component';
import { FormControl, Validators } from '@angular/forms';
import { IAdvancedFilterData } from '@interfaces';

@Component({
  selector: 'aup-advanced-filters-status',
  templateUrl: './advanced-filters-status.component.html',
  styleUrls: ['./advanced-filters-status.component.scss'],
  standalone: false,
})
export class AdvancedFiltersStatusComponent extends AdvancedFiltersFilterItemBase {
  initForm() {
    this.formGroup = this.fb.group({
      status: [null, Validators.required],
    });
  }

  get formControl(): FormControl {
    return this.formGroup.get('status') as FormControl;
  }

  getFilterValues(): Partial<IAdvancedFilterData> {
    if (!this.formGroup.valid) {
      return {};
    }

    return {
      value: this.formControl.value,
      complexValue: null,
    };
  }
}
