import { Component, Inject, Injector } from '@angular/core';
import { SuperDialogComponent } from '../super-dialog/super-dialog.component';
import { EDialogCloseStatus, EDialogResultAction } from '@core/enums';
import { IDialogResult, IDialogSizeMap } from '@core/interfaces';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * UnsavedChangesProtectionModalComponent
 *
 * @export
 * @class UnsavedChangesProtectionModalComponent
 * @typedef {UnsavedChangesProtectionModalComponent}
 * @extends {SuperDialogComponent<UnsavedChangesProtectionModalComponent>}
 */
@Component({
  selector: 'aup-unsaved-changes-protection-modal',
  templateUrl: './unsaved-changes-protection-modal.component.html',
  styleUrls: ['./unsaved-changes-protection-modal.component.scss'],
  standalone: false,
})
export class UnsavedChangesProtectionModalComponent extends SuperDialogComponent<UnsavedChangesProtectionModalComponent> {
  /**
   * @inheritdoc
   *
   * @type {string}
   */
  override content: string = 'default';
  /**
   * Dialog sizing map depend on content/screen size
   *
   * @type {IDialogSizeMap}
   */
  sizesMap: IDialogSizeMap = {
    default: {
      small: {
        width: '100vw',
        height: 'auto',
      },
      other: {
        width: '600px',
        height: 'auto',
      },
    },
  };

  /**
   * Creates an instance of UnsavedChangesProtectionModalComponent.
   *
   * @constructor
   * @param {Injector} injector
   * @param {MatDialogRef<UnsavedChangesProtectionModalComponent, IDialogResult>} dialogRef
   */
  constructor(
    override injector: Injector,
    override dialogRef: MatDialogRef<UnsavedChangesProtectionModalComponent, IDialogResult>
  ) {
    super(injector, dialogRef);
  }

  /**
   * On calcel handler
   */
  onCancel() {
    this.onClose({ status: EDialogCloseStatus.CANCEL });
  }

  /**
   * Don`t save and continue handler
   */
  onDontSaveAndContinue() {
    this.onClose({ status: EDialogCloseStatus.OK, action: EDialogResultAction.DONT_SAVE_AND_CONTINUE });
  }

  /**
   * Save and continue handler
   */
  onSaveAndContinue() {
    this.onClose({ status: EDialogCloseStatus.OK, action: EDialogResultAction.SAVE_AND_CONTINUE });
  }
}
