import { ESentInvoiceStatus } from '../enums';
import { AttachedDocument } from './attached-document.model';
import { RecurringInvoice } from './recurring-invoice.model';
import { SuperModel } from './super-model.model';
import moment from 'moment';

export class SentInvoiceListItem extends SuperModel {
  username: string;
  position: number;
  fileType: string;
  status: ESentInvoiceStatus;
  number: string;
  orderNumber: string;
  date: Date;
  creationDate: Date;
  type: string;
  receiverType: string;
  receiver: string;
  documentTotalImport: number;
  documentTotalAmount: number;
  uploadFileName: string;
  valorizedFile: boolean;
  fileName: string;
  sentCorrectId: string;
  preserved: boolean;
  attached: boolean;
  imported: boolean;
  deletable: boolean;
  sdiId: string;
  netToPay: number;
  vatTotal: number;
  taxableTotal: number;
  nonTaxableTotal: number;
  collectionPaymentStatus: string;
  collectionPaymentDate: Date;
  installmentStatusIcon: number;
  manualNetImportToPay: number;
  xsdFormat: number;
  unsupported: boolean;
  priceMode: string;
  primaryCode: string;
  secondaryCode: string;
  personalDataAssociation: string;
  creditNoteAssociation: string;
  creditNoteAssociationPosition: number;
  associatedCreditNoteNumber: number;
  associatedCreditNoteImport: number;
  dealsVolume: number;
  autoInvoice: boolean;
  enableCreditNoteCreation: boolean;
  attachedDocuments: AttachedDocument[];
  shop: string;
  groupChannel: number;
  pdfLanguage: string;
  recurringInvoiceData: RecurringInvoice;
  importOrigins: string;

  constructor(data: any) {
    super(data);

    this.mapDates(data);
    this.mapAttachedDocuments(data);
    this.mapRecurringInvoiceData(data);
  }

  get canDownloadP7M() {
    return this.uploadFileName.includes('p7m');
  }

  private mapRecurringInvoiceData(data: any) {
    if (data.recurringInvoiceData) {
      this.recurringInvoiceData = new RecurringInvoice(data.recurringInvoiceData);
    }
  }

  private mapAttachedDocuments(data: any) {
    if (data.attachedDocuments) {
      this.attachedDocuments = data.attachedDocuments.map(document => new AttachedDocument(document));
    }
  }

  private mapDates(data: any) {
    if (data.date) {
      this.date = moment(data.date).toDate();
    }

    if (data.creationDate) {
      this.creationDate = moment(data.creationDate).toDate();
    }

    if (data.collectionPaymentDate) {
      this.collectionPaymentDate = moment(data.collectionPaymentDate).toDate();
    }
  }

  get total() {
    return this.netToPay + this.vatTotal;
  }

  override get serialised() {
    return {};
  }
}
