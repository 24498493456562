// FATTURE
// 1) TD01 Fattura
// 2) TD02 Acconto/anticipo su fattura
// 3) TD03 Acconto/anticipo su parcella
// 4) TD06 Parcella
// 5) TD07 Fattura semplificata
// 6) TD24 Fattura differita di cui all'art. 21, comma 4, lett. a
// 7) TD25 Fattura differita di cui all'art. 21, comma 4, terzo periodo lett. b
// 8) TD26 Cessione di beni ammortizzabili
// Note di variazione
// 9) TD04 Nota di credito
// 10) TD05 Nota di debito
// 11) TD08 Nota di credito semplificata
// 12) TD09 Nota di debito semplificata
// Autofatture
// 13) TD01A Autofattura
// 14) TD16 Integrazione fattura reverse charge interno
// 15) TD17 Integrazione/autofattura per acquisto servizi dall'estero
// 16) TD18 Integrazione/autofattura per acquisto di beni intracomunitari
// 17) TD19 Integrazione/autofattura per acquisto di beni ex art.17 c.2 DPR 633/72
// 18) TD20 Autofattura per regolarizzazione e integrazione delle fatture
// 19) TD21 Autofattura per splafonamento
// 20) TD22 Estrazione beni da Deposito IVA
// 21) TD23 Estrazione beni da Deposito IVA con versamento dell'IVA
// 22) TD26A Autofattura per passaggi interni (ex art.36 DPR 633/72)
// 23) TD27 Fattura per autoconsumo o per cessioni gratuite senza rivalsa
// 24) TD28 Acquisti da San Marino con IVA (fattura cartacea)
// 25) ARU_TD01 - Fattura cartacea
// 26) ARU_TD04 - Nota di credito cartacea

export enum EDocumentType {
  TD01 = 'TD01',
  TD02 = 'TD02',
  TD03 = 'TD03',
  TD06 = 'TD06',
  TD07 = 'TD07',
  TD24 = 'TD24',
  TD25 = 'TD25',
  TD26 = 'TD26',
  TD04 = 'TD04',
  TD05 = 'TD05',
  TD08 = 'TD08',
  TD09 = 'TD09',
  TD01A = 'TD01a',
  TD16 = 'TD16',
  TD17 = 'TD17',
  TD18 = 'TD18',
  TD19 = 'TD19',
  TD20 = 'TD20',
  TD21 = 'TD21',
  TD22 = 'TD22',
  TD23 = 'TD23',
  TD26A = 'TD26a',
  TD27 = 'TD27',
  TD28 = 'TD28',
  ARU_TD01 = 'ARU_TD01',
  ARU_TD04 = 'ARU_TD04',
}

export enum EInvoiceFileType {
  STANDARD_INVOICE = 1,
  BATCH_OF_INVOICES = 2,
  SIMPLIFIED_INVOICE = 3,
}

export enum EAttachedDocumentType {
  INVOICE = 'Fattura', //Fattura
  PRV = 'PRV', //Preventivo
  NDC = 'NDC', //Nota di credito
  DRF_FT = 'DRF_FT', //Bozza
  ODV = 'ODV', //Ordine di Vendita
  ODA = 'ODA', //Ordine di Acquisto
  DDT = 'DDT', //DDT
  FPR = 'FPR', //Proforma
  NSO = 'NSO', //Ordine elettronico
  NDCNE = 'NDCNE', // Nota di credito cartacea
  FTNE = 'FTNE', // Fattura cartacea
}

export enum EClientType {
  PRIVATE = 'FPR12', //Privato
  PA = 'FPA12', //PA
  SIMPLIFIED = 'FSM10', //Semplificato
}

export enum EBillingValueType {
  PAYMENT_REASON = 'PAYMENT_REASON', //causalepagamento
  PAYMENT_METHOD = 'PAYMENT_METHOD', //"modalitapagamento"
  TRANSPORT_REASON = 'TRANSPORT_REASON', //"causaletrasporto"
  NATURE = 'NATURE', //"nature"
  NATURE_OF_AMOUNTS_SUMMARY = 'NATURE_OF_AMOUNTS_SUMMARY', //"nature_riepilogoimporti"
  DOCUMENT_TYPE = 'DOCUMENT_TYPE', //"tipodocumento" && "tipodocumentononelettronico"
  TAX_TYPE = 'TAX_TYPE', //"tiporitenuta"
}
