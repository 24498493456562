import { marker } from '@jsverse/transloco-keys-manager/marker';
import { SuperModel } from './super-model.model';

export class PecPermission extends SuperModel {
  value: string;
  active: boolean;
  require: string[];

  constructor(data: any = {}) {
    super(data);

    // hardcoded
    if (data.value === 'INVIO') {
      this.require = ['SCRITTURA'];
    }
  }

  get serialised() {
    return {};
  }
}

// static translations
const staticPermissionsTranslations = [
  marker('PEC.PERMISSIONS.TITLE.LETTURA'),
  marker('PEC.PERMISSIONS.TITLE.SCRITTURA'),
  marker('PEC.PERMISSIONS.TITLE.RINNOVO_UPGRADE'),
  marker('PEC.PERMISSIONS.TITLE.INVIO'),
  marker('PEC.PERMISSIONS.TITLE.IMPOSTAZIONI'),
  marker('PEC.PERMISSIONS.DESCRIPTION.LETTURA'),
  marker('PEC.PERMISSIONS.DESCRIPTION.SCRITTURA'),
  marker('PEC.PERMISSIONS.DESCRIPTION.RINNOVO_UPGRADE'),
  marker('PEC.PERMISSIONS.DESCRIPTION.INVIO'),
  marker('PEC.PERMISSIONS.DESCRIPTION.IMPOSTAZIONI'),
];
