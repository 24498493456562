<form [formGroup]="formGroup" class="flex gap-2 w-full flex-wrap sm:flex-nowrap">
  <div class="w-[200px] sm:w-[150px]">
    <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'" class="w-full">
      <mat-select
        [panelWidth]="null"
        [placeholder]="'COMMON.PLACEHOLDER.SELECT' | transloco"
        formControlName="type"
      >
        <mat-option *ngFor="let comparator of textComparatorOptions" [value]="comparator.value">
          {{ comparator.text | transloco }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <ng-container *ngIf="formGroup.value.type">
    <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'" class="w-full">
      <input
        matInput
        formControlName="text"
        type="text"
        [placeholder]="'ADVANCED_FILTERS.PLACEHOLDER.SEARCH' | transloco"
      />
    </mat-form-field>
  </ng-container>
</form>
