export function arraysEqual(arrayA: any[], arrayB: any[]) {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  const sortedA = arrayA.slice().sort();
  const sortedB = arrayB.slice().sort();

  return sortedA.every((value, index) => value === sortedB[index]);
}
