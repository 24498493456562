import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, tap } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LanguagesService {
  private currentLanguageSubject: BehaviorSubject<string>;
  public currentLang = 'it';
  public isTranslationsLoaded = false;

  constructor(
    private translocoService: TranslocoService,
    private httpClient: HttpClient
  ) {
    this.currentLanguageSubject = new BehaviorSubject('');
    this.translocoService
      .selectTranslation()
      .pipe(tap(() => (this.isTranslationsLoaded = true)))
      .subscribe();
  }

  public setCurrentLanguage(language: string, urlTranslate: string, i18n?: any) {
    this.loadTranslation(language, urlTranslate, i18n);
    this.currentLanguageSubject.next(language);
    this.currentLang = language;
  }

  loadTranslation(language: string, url: string, i18n?: any) {
    this.httpClient.get(url + '/' + language + '.json').subscribe(
      res => {
        this.translocoService.setTranslation(res, language, { merge: true });
        this.translocoService.setActiveLang(language);
      },
      () => {
        this.translocoService.setTranslation(i18n, language, { merge: true });
        this.translocoService.setActiveLang(language);
      }
    );
  }
}
